import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
// components
import ChatBotWidget from '../../../components/ChatBotWidget';
import LoaderVariant2 from '../../../components/LoaderVariant2';
// constants, utilities
import {
  getCookie,
  setCookie,
  verifySalesUserAuthKey,
} from '../../../common/utility';
import { SALES_USER_EMAIL, SALES_USER_TOKEN } from '../../../constants';

const SalesChatbotPage: FC = () => {
  const history = useHistory();
  const salesUserToken = getCookie(SALES_USER_TOKEN);
  const [authenticated, setAuthenticated] = useState(false);

  const navigateToLogin = () => {
    setCookie(SALES_USER_TOKEN, '', -1);
    setCookie(SALES_USER_EMAIL, '', -1);
    history.replace('/ca_sales/login');
  };

  const verifyAuthKey = async (key: string) => {
    const data = await verifySalesUserAuthKey(key);
    if (!data || data.key !== salesUserToken) {
      navigateToLogin();
    } else {
      setAuthenticated(true);
    }
  };

  useEffect(() => {
    if (salesUserToken) {
      verifyAuthKey(salesUserToken);
    } else {
      navigateToLogin();
    }
  }, [salesUserToken]);

  return authenticated ? (
    <ChatBotWidget isAdvisorChatbot={false} handleClose={() => {}} />
  ) : (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LoaderVariant2 isSmallLoader />
    </Box>
  );
};

export default SalesChatbotPage;
