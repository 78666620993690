import {
  Box,
  Grid,
  Button,
  Tooltip,
  Container,
  makeStyles,
  Typography,
  TooltipProps,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { FC, Fragment, useEffect, useState } from 'react';
// components
import { Form, Formik } from 'formik';
import Logo from '../../../components/layout/Logo';
// constants, utilities
import {
  getCookie,
  setCookie,
  verifySalesUserAuthKey,
} from '../../../common/utility';
import { SALES_USER_EMAIL, SALES_USER_TOKEN } from '../../../constants';
// styles
import { useStyles } from '../styles';
// assets
import fonts from '../../../common/fonts';
import colors from '../../../common/colors';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    width: 22,
    height: 16,
    marginLeft: -4,
    color: colors.GRAY_TAB_BORDER,
    marginBottom: '-16px !important',
  },

  tooltip: {
    padding: 16,
    borderRadius: 6,
    backgroundColor: colors.GRAY_TAB_BORDER,
    border: `1px solid ${colors.TOOLTIP_BORDER}`,
    boxShadow: `0px 0px 1px 0px ${colors.TOOLTIP_SHADOW_PRIMARY}, 0px 40px 64px 0px ${colors.TOOLTIP_SHADOW_SECONDARY}`,

    "& [class*='MuiTypography-body1']": {
      fontSize: 12,
      fontWeight: 400,
      color: colors.WHITE_ONE,
      fontFamily: fonts.INTER,
    },

    '& a': {
      fontSize: 12,
      marginLeft: 5,
      fontWeight: 400,
      color: colors.WHITE_ONE,
      fontFamily: fonts.INTER,
      borderBottom: `1px solid ${colors.WHITE_ONE}`,
    },
  },
}));

const SalesChatbotLoginPage: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const salesUserToken = getCookie(SALES_USER_TOKEN);

  const [key, setKey] = useState('');
  const [error, setError] = useState(false);

  const formatKey = (value: string) => {
    // Remove all non-alphanumeric characters
    const rawValue = value.replace(/[^a-zA-Z0-9]/g, '');
    // Replace all characters with "x" and format as xxxx-xxxx-xxxx-xxxx
    const maskedValue = rawValue.replace(/./g, 'x');
    // Insert dashes every 4 characters
    return maskedValue.match(/.{1,4}/g)?.join('-') || maskedValue;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setKey(inputValue);

    if (inputValue.length < 7) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const verifyAuthKey = async () => {
    const data = await verifySalesUserAuthKey(key);
    if (data) {
      const { email, key } = data;
      setCookie(SALES_USER_TOKEN, key, 8);
      setCookie(SALES_USER_EMAIL, email, 8);
      history.replace('/ca_sales');
    } else {
      alert('Incorrect Access Key');
    }
  };

  function BootstrapTooltip(props: JSX.IntrinsicAttributes & TooltipProps) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  useEffect(() => {
    if (salesUserToken) {
      history.replace('/ca_sales');
    }
  }, [salesUserToken]);

  return (
    <div>
      <Box
        padding="14px 0px"
        borderBottom={`1px solid ${colors.GRAY_EIGHT}`}
        boxShadow={`0px 4px 20px ${colors.BOX_SHADOW_THREE}`}
      >
        <Container maxWidth={false}>
          <Logo />
        </Container>
      </Box>

      <Container maxWidth={false}>
        <Grid className={classes.contentHeightVh}>
          <Box maxWidth={435} textAlign="center" margin="0px auto" pt={8}>
            <Box
              mb={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3">CollegeAdvisor AI</Typography>

              <BootstrapTooltip
                title={
                  <Fragment>
                    <Typography variant="body1">
                      This tool is designed to evolve and improve over time.
                      Please use responsibly and verify critical information as
                      needed.
                      <a
                        href="https://www.notion.so/collegeadvisor/CollegeAdvisor-AI-Beta-SALES-6874ccc59fb54b64827322a766201d1a?pvs=4"
                        target="_blank"
                      >
                        Learn More
                      </a>
                    </Typography>
                  </Fragment>
                }
                arrow
                interactive
                placement="top"
              >
                <Box className="betaBadge">Beta</Box>
              </BootstrapTooltip>
            </Box>

            <Typography variant="body1">
              Please contact your supervisor to obtain your access key.
            </Typography>

            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting }) => {
                if (error) return;
                try {
                  verifyAuthKey();
                } catch (error: any) {
                  alert('Something went wrong, Please try again');
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="baseline"
                    justifyContent="center"
                  >
                    <Box textAlign="left">
                      <input
                        name="authKey"
                        type="password"
                        minLength={7}
                        value={key}
                        placeholder="Enter Key"
                        onChange={handleChange}
                        style={{
                          border: error
                            ? `1px solid ${colors.RED_FOUR}`
                            : `1px solid ${colors.WHITE_SEVEN}`,
                        }}
                      />
                      {error && (
                        <Typography
                          variant="body1"
                          color="error"
                          className="helperTextError"
                        >
                          The key you entered is not valid. Please try again.
                        </Typography>
                      )}
                    </Box>

                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className="submitButton"
                      disabled={isSubmitting && error}
                    >
                      Submit
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Container>
    </div>
  );
};

export default SalesChatbotLoginPage;
