import { IconButton } from '@material-ui/core';
import { FC, Fragment, useState, useEffect } from 'react';
// components
import ChatBotWidget from './ChatBotWidget';
// assets
import ChatBotIcon from '../img/chatbot-widget-icon.png';
// styles
import { useStyles } from './styles';
import {
  logUserActivity,
  UserActivityActions,
  UserActivityType,
} from '../constants';
import { LocationInterface } from '../common/interfaces';
import { useLocation } from 'react-router-dom';

const ChatBotDialogWidget: FC = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { pathname, search, state }: LocationInterface = useLocation();
  const query = new URLSearchParams(search);
  const origin = query.get('origin');

  const logChatActivity = async () => {
    const payload = {
      pageName: pathname,
      action: UserActivityActions.CLICKED_CHATBOT,
      type: UserActivityType.CHAT_BOT,
      origin: origin || state?.from || null,
    };
    logUserActivity(payload);
  };

  const toggleIframe = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (window.Intercom) {
      if (isOpen) {
        window.Intercom('hide');
        logChatActivity();
      }
    }
  }, [isOpen]);

  return (
    <Fragment>
      <div className={classes.positionRelative}>
        <div
          style={{
            opacity: isOpen ? 1 : 0,
            visibility: isOpen ? 'visible' : 'hidden',
            transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
          }}
        >
          {isOpen && (
            <ChatBotWidget
              isOpen={isOpen}
              handleClose={handleClose}
              isAdvisorChatbot
            />
          )}
        </div>

        <IconButton
          onClick={toggleIframe}
          className={classes.chatbotToggleButton}
        >
          <img src={ChatBotIcon} alt="ChatBot" width="52.8px" />
        </IconButton>
      </div>
    </Fragment>
  );
};

export default ChatBotDialogWidget;
