import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { Field, FieldArray, Form, Formik } from 'formik';
import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import Select from 'react-select';
import { TextField } from '../../../components/fields';

import client from '../../../apollo';

import { StudentApplicationMajorInput } from '../../../../__generated__/globalTypes';
import { customTheme } from '../../MaterializeCss';
import DeleteIcon from '../../../img/delete-payouts.svg';

import { useStylesAccount } from '../account/accountStyle';
import { MAJOR_CATEGORY_QUERY } from '../account/EditEducation';
import {
  StudentApplicationDetailNewQuery_StudentApplication_studentApplicationMajors as getStudentApplicationMajors,
  StudentApplicationDetailNewQuery_StudentApplication_applicationRequirement_college_allMajors as getCollegeMajor,
} from './graphql/queries/applicationsPage/__generated__/StudentApplicationDetailNewQuery';
import { AddFilledRoundIcon } from './assets/svg';

export const SET_STUDENT_APPLICATION_MAJORS = gql`
  mutation setStudentApplicationMajors(
    $applicationId: Int!
    $majors: [StudentApplicationMajorInput]
  ) {
    setStudentApplicationMajors(
      applicationId: $applicationId
      majors: $majors
    ) {
      success
      message
    }
  }
`;

export const DELETE_STUDENT_APPLICATION_MAJOR = gql`
  mutation deleteStudentApplicationMajor(
    $input: deleteStudentApplicationMajorInput
  ) {
    deleteStudentApplicationMajor(input: $input) {
      success
      message
    }
  }
`;

const AddEditApplicationMajor = ({
  open,
  info,
  onClose,
  refetch,
  majors,
  studentId,
  applicationId,
  applicationCampusId,
  userMajorData,
  isBSMD = false,
}: {
  open: boolean;
  info: 'graduation' | 'underGraduation';
  onClose: Function;
  studentId?: number;
  applicationId: number | undefined;
  applicationCampusId?: number | undefined;
  isBSMD?: boolean | null;
  majors: (getCollegeMajor | null)[] | null | undefined;
  refetch: Function;
  userMajorData: (getStudentApplicationMajors | null)[] | null;
}) => {
  const classes = useStylesAccount();
  const [collegeMajors, setCollegeMajors] = useState<any>([
    {
      value: 0,
      label: 'Other',
      areaOfInterest: null,
    },
  ]);
  const [allAreaOfInterest, setAllAreaOfInterest] = useState<any>(null);
  const [values, setValues] = useState<any>([]);

  const FormSchema = Yup.object().shape({
    userMajorData: Yup.array().of(
      Yup.object().shape({
        MajorId: Yup.number().when('isOtherMajor', {
          is: false,
          then: Yup.number().required('Major is required'),
          otherwise: Yup.number().nullable(),
        }),
        otherMajor: Yup.string().when('isOtherMajor', {
          is: true,
          then: Yup.string().required('This field cannot be left blank'),
          otherwise: Yup.string(),
        }),
        MajorCategoryId: Yup.number().required('Area of interest is required'),
      })
    ),
  });

  const handleClose = (resetForm: Function) => {
    resetForm();
    onClose();
  };

  const mountEffect = async () => {
    try {
      const { data } = await client.query({
        query: MAJOR_CATEGORY_QUERY,
        variables: { limit: 100 },
        fetchPolicy: 'network-only',
      });
      const allMajorCategories = data?.getMajorCategories?.map(
        (item: { id: any; name: string }) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );
      setAllAreaOfInterest(allMajorCategories);
    } catch (error) {
      console.log('Error:::', error);
    }
  };

  const deleteMajor = async (applicationMajorId: number) => {
    await client.mutate({
      mutation: DELETE_STUDENT_APPLICATION_MAJOR,
      variables: {
        input: {
          studentApplicationMajorId: applicationMajorId,
        },
      },
    });
    refetch && (await refetch());
  };

  useEffect(() => {
    mountEffect();
  }, []);

  const getMajor = (majorId: any) => {
    return majors ? majors?.find((item) => item?.id == majorId) : null;
  };

  const groupMajorsByCategory = (data: any) => {
    const groupedOptions: {
      label: string;
      options: { value: number; label: string; areaOfInterest: any }[];
    }[] = [];

    data?.forEach((item: { majorCategory: any; id: number; name: string }) => {
      const category = item.majorCategory;
      const existingGroup = groupedOptions?.find(
        (group) => group.label === category.name
      );

      if (existingGroup) {
        existingGroup.options.push({
          value: item.id,
          label: item.name,
          areaOfInterest: item?.majorCategory,
        });
      } else {
        groupedOptions.push({
          label: category.name,
          options: [
            {
              value: item.id,
              label: item.name,
              areaOfInterest: item?.majorCategory,
            },
          ],
        });
      }
    });

    groupedOptions.sort((a, b) => a.label.localeCompare(b.label));

    return groupedOptions;
  };

  const saveApplicationMajors = async (
    majors: StudentApplicationMajorInput[]
  ) => {
    await client.mutate({
      mutation: SET_STUDENT_APPLICATION_MAJORS,
      variables: {
        applicationId,
        majors,
      },
    });
  };

  useEffect(() => {
    const allMajors = groupMajorsByCategory(majors);
    allMajors?.unshift({
      label: '',
      options: [
        {
          value: 0,
          label: 'Other',
          areaOfInterest: {},
        },
      ],
    });
    setCollegeMajors(allMajors);

    const newArr = userMajorData?.length
      ? userMajorData?.map((item) => {
          const currentMajor = getMajor(item?.MajorId);
          return {
            id: item?.id,
            MajorId: item?.MajorId,
            otherMajor: item?.otherMajor ? item?.name : '',
            MajorCategoryId: item?.majorCategory?.id,
            isOtherMajor: item?.otherMajor ? true : false,
            ApplicationCampusId: applicationCampusId,
          };
        })
      : [
          {
            MajorId: null,
            id: null,
            otherMajor: '',
            MajorCategoryId: null,
            isOtherMajor: false,
            ApplicationCampusId: applicationCampusId,
          },
        ];

    setValues(newArr);
  }, [userMajorData]);

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      fontWeight: 'normal',
    }),
    groupHeading: (provided: any) => ({
      ...provided,
      fontWeight: 600,
      color: '#272929',
    }),
  };

  return (
    <Formik
      initialValues={{
        userMajorData: values,
      }}
      enableReinitialize
      validationSchema={FormSchema}
      onSubmit={async (variables, { setSubmitting }) => {
        try {
          await saveApplicationMajors(variables.userMajorData);
        } catch (error: any) {
          console.log('ERROR', error?.message);
        } finally {
          refetch && (await refetch());
          setSubmitting(false);
          onClose();
        }
      }}
    >
      {({
        isSubmitting,
        setFieldValue,
        handleSubmit,
        values,
        errors,
        resetForm,
        dirty,
      }) => {
        const isError = !!Object.keys(errors).length;
        return (
          <Form>
            <Dialog
              open={open}
              onClose={() => handleClose(resetForm)}
              className={clsx(
                classes.educationDialog,
                classes.undergraduateEducation
              )}
            >
              <DialogContent className="undergraduateEducationDialog">
                <Box className="dialogHeader">
                  <Typography variant="h4">Application Major</Typography>
                </Box>

                <Box className="dialogScroll">
                  <Grid
                    container
                    spacing={2}
                    className={classes.accountInformationForm}
                  >
                    <FieldArray name="userMajorData">
                      {(arrayHelpers) => (
                        <Box width="100%">
                          {values.userMajorData?.map(
                            (item: any, index: number) => (
                              <>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{ margin: 0 }}
                                >
                                  <Grid
                                    item
                                    lg={8}
                                    md={8}
                                    xs={9}
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <Box
                                      className={`${classes.advisorSelectDropDown} styleTextField fieldsBoxSpacing styleTextFieldDialog`}
                                    >
                                      <Typography
                                        variant="body1"
                                        className="textFieldCaption textFieldCaptionDialog"
                                      >
                                        Major
                                      </Typography>

                                      <Select
                                        menuPlacement="bottom"
                                        styles={customStyles}
                                        placeholder="Select"
                                        searchable={true}
                                        options={collegeMajors}
                                        value={
                                          !values.userMajorData[index]
                                            ?.isOtherMajor
                                            ? collegeMajors.flatMap(
                                                (group: { options: any[] }) =>
                                                  group.options?.find(
                                                    (option: { value: any }) =>
                                                      values?.userMajorData &&
                                                      values?.userMajorData[
                                                        index
                                                      ]?.MajorId == option.value
                                                  )
                                              )
                                            : collegeMajors[0]?.options[0]
                                        }
                                        name="colors"
                                        classNamePrefix={'react-select'}
                                        className="react-select-container"
                                        onChange={(value: any) => {
                                          if (!value?.value) {
                                            setFieldValue(
                                              `userMajorData.${index}.isOtherMajor`,
                                              true
                                            );
                                            setFieldValue(
                                              `userMajorData.${index}.MajorId`,
                                              null
                                            );
                                          } else {
                                            setFieldValue(
                                              `userMajorData.${index}.MajorId`,
                                              value?.value
                                            );
                                            setFieldValue(
                                              `userMajorData.${index}.isOtherMajor`,
                                              false
                                            );
                                            setFieldValue(
                                              `userMajorData.${index}.otherMajor`,
                                              ''
                                            );
                                            setFieldValue(
                                              `userMajorData.${index}.MajorCategoryId`,
                                              value?.areaOfInterest?.id
                                            );
                                          }
                                        }}
                                      ></Select>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={1}
                                    md={1}
                                    xs={3}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      paddingBottom: 0,
                                    }}
                                  >
                                    {values.userMajorData &&
                                      values.userMajorData?.length >= 2 && (
                                        <IconButton
                                          onClick={async () => {
                                            arrayHelpers.remove(index);
                                            if (item?.id) {
                                              await deleteMajor(item?.id);
                                            }
                                          }}
                                          className="deleteButton"
                                        >
                                          <img src={DeleteIcon} alt="delete" />
                                        </IconButton>
                                      )}
                                  </Grid>

                                  {values.userMajorData[index].isOtherMajor && (
                                    <Grid
                                      item
                                      lg={3}
                                      md={3}
                                      xs={12}
                                      style={{ padding: 0 }}
                                    ></Grid>
                                  )}

                                  {values.userMajorData[index].isOtherMajor && (
                                    <Grid item lg={8} md={8} xs={12}>
                                      <Box className="styleTextField fieldsBoxSpacing styleTextFieldDialog">
                                        <Typography
                                          variant="body1"
                                          className="textFieldCaption textFieldCaptionDialog"
                                        >
                                          Major Name
                                        </Typography>
                                        <Field
                                          name={`userMajorData.${index}.otherMajor`}
                                          component={TextField}
                                          label=""
                                          placeholder="e.g. Psychology"
                                        />
                                      </Box>
                                    </Grid>
                                  )}

                                  {values.userMajorData[index].isOtherMajor && (
                                    <Grid
                                      item
                                      lg={1}
                                      md={1}
                                      xs={12}
                                      style={{ padding: 0 }}
                                    ></Grid>
                                  )}

                                  <Grid
                                    item
                                    lg={3}
                                    md={3}
                                    xs={12}
                                    style={{ padding: 0 }}
                                  ></Grid>

                                  <Grid
                                    item
                                    lg={8}
                                    md={8}
                                    xs={12}
                                    style={{ paddingTop: 0 }}
                                  >
                                    <Box
                                      className={`${classes.advisorSelectDropDown} styleTextField fieldsBoxSpacing styleTextFieldDialog`}
                                    >
                                      <Typography
                                        variant="body1"
                                        className="textFieldCaption textFieldCaptionDialog"
                                      >
                                        Area of Interest
                                      </Typography>

                                      <FormControl fullWidth={true}>
                                        <Select
                                          menuPlacement="top"
                                          placeholder="Select Area of Interest"
                                          displayEmpty
                                          options={allAreaOfInterest}
                                          isDisabled={
                                            !values.userMajorData[index]
                                              .isOtherMajor
                                          }
                                          value={
                                            values.userMajorData[index]
                                              .MajorCategoryId
                                              ? allAreaOfInterest?.find(
                                                  (item: { value: number }) =>
                                                    values.userMajorData &&
                                                    values.userMajorData[index]
                                                      .MajorCategoryId ==
                                                      item?.value
                                                )
                                              : null
                                          }
                                          onChange={(value) => {
                                            setFieldValue(
                                              `userMajorData.${index}.MajorCategoryId`,
                                              value?.value
                                            );
                                          }}
                                          classNamePrefix={'react-select'}
                                          className="react-select-container"
                                        ></Select>
                                      </FormControl>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    lg={3}
                                    xs={12}
                                    style={{ padding: 0 }}
                                  ></Grid>
                                </Grid>

                                <>
                                  <Divider className="sectionDivider" />

                                  {values?.userMajorData &&
                                    values?.userMajorData?.length < 2 && (
                                      <Button
                                        onClick={() => {
                                          arrayHelpers.push({
                                            MajorId: null,
                                            otherMajor: '',
                                            MajorCategoryId: null,
                                            isOtherMajor: false,
                                            ApplicationCampusId:
                                              applicationCampusId,
                                          });
                                        }}
                                        color="primary"
                                        variant="text"
                                        startIcon={<AddFilledRoundIcon />}
                                      >
                                        ADD NEW MAJOR
                                      </Button>
                                    )}
                                </>
                              </>
                            )
                          )}
                        </Box>
                      )}
                    </FieldArray>
                  </Grid>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  className="dialogAction"
                >
                  <Button
                    variant="outlined"
                    onClick={() => handleClose(resetForm)}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    disabled={isSubmitting || isError || !dirty}
                    onClick={() => handleSubmit()}
                  >
                    {isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEditApplicationMajor;
