import { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Intercom from 'react-intercom';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { withSendBird } from 'sendbird-uikit';
import Header from '../components/layout/Header';
import { AppContext } from '../contexts';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { Error404, Maintenance } from './errorPages';
import { ForgotPasswordPage, LoginPage, ResetPasswordPage } from './login';
import HomePage from './main/HomePage';
import Payouts from './main/Payouts';
import { BillingPage, ChangePasswordPage, ProfilePage } from './main/account';
import AccountInformation from './main/account/AccountInformation';
import ConnectWithCalendar from './main/calendar';
import CalendarCallback from './main/calendar/CalendarCallback';
import Capacity from './main/capacity';
import { Chat } from './main/chat';
import MainLayout from './main/layouts/MainLayout';
import Meetings from './main/meetings';
import { StudentDetailsPage, StudentsPage } from './main/students';
import ApplicationDetailPage from './main/students/ApplicationDetailPage';
import FindOpportunity from './main/students/FindOpportunity';
import IntakeFormQA from './main/students/IntakeFormQA';
import SubmitSummerOppotunity from './main/summerOppotunites/createSummerOppotunity';
import TimeLogs from './main/timeLogs';
import NewTimeLogPage from './main/timeLogs/NewTimeLogPage';
import TimeSlots from './main/timeSlots';

import SalesChatbotPage from './main/SalesChatbot/SalesChatbotPage';
import SalesChatbotLoginPage from './login/SalesChatbot/SalesChatbotLoginPage';
import ChatBotDialogWidget from '../components/ChatBotDialogWidget';
import { Box } from '@material-ui/core';

function Pages(props: any) {
  const appContext = useContext(AppContext);
  const user = appContext && appContext.user;

  const { setUnreadMessagesCount } = useContext(AppContext);

  let initialized: any;
  let sdk: any;

  if (props && props.state) {
    initialized = props.state.stores.sdkStore.initialized;
    sdk = props.state.stores.sdkStore.sdk;
  }

  useEffect(() => {
    if (initialized) {
      const { userId } = sdk.currentUser;
      const UNIQUE_USER_HANDLER_ID = `${userId}-user-handler`;

      var userEventHandler = new sdk.UserEventHandler();

      userEventHandler.onTotalUnreadMessageCountUpdated = function (
        totalCount: number
      ) {
        setUnreadMessagesCount({
          user,
          isLoggedIn: true,
          unreadMessagesCount: totalCount,
        });
      };

      sdk.addUserEventHandler(UNIQUE_USER_HANDLER_ID, userEventHandler);
      return () => {
        sdk.removeChannelHandler(UNIQUE_USER_HANDLER_ID);
      };
    }
  }, [user, sdk]);

  const tagManagerArgs = {
    gtmId: 'GTM-TNDVJ6D',
    dataLayer: {
      user,
    },
  };

  // Initialize GTM in production only
  if (process.env.NODE_ENV === 'production')
    TagManager.initialize(tagManagerArgs);

  const intercomUser = {
    user_id: user && user.id,
    email: user && user.email,
    firstName: user && user.firstName,
    lastName: user && user.lastName,
    name: user && `${user.firstName} ${user.lastName}`,
  };

  return (
    <Router>
      <MainLayout>
        {!window.location.pathname.startsWith('/ca_sales') && (
          <Intercom appID="rn50ve5d" {...intercomUser} />
        )}

        {user?.isActive && (
          <Box className="chatbotWidget">
            <ChatBotDialogWidget />
          </Box>
        )}

        <Switch>
          <Route
            exact
            path={'/ca_sales/login'}
            component={SalesChatbotLoginPage}
          />

          <Route exact path={'/maintenance'} component={Maintenance} />
          <Route exact path={'/'} component={HomePage} />
          <Route exact path={`/error`} component={Error404} />
          <Route exact path={`/maintenance`} component={Maintenance} />
          <Route exact path={`/login`} component={LoginPage} />
          <Route
            path={`/reset-password/:token`}
            component={ResetPasswordPage}
          />
          <Route
            exact
            path={`/forgot-password`}
            component={ForgotPasswordPage}
          />

          <Route exact path={`/ca_sales`} component={SalesChatbotPage} />

          <PrivateRoute exact path={'/students'} component={StudentsPage} />
          <PrivateRoute
            exact
            path={'/students/student-details/:id'}
            component={StudentDetailsPage}
          />
          <PrivateRoute
            exact
            path={'/students/application/:id'}
            component={ApplicationDetailPage}
          />

          <PrivateRoute
            exact
            path={'/students/student-details/:id/intakeForm/:formToken'}
            component={IntakeFormQA}
          />
          <PrivateRoute
            exact
            path={'/students/student-details/:id/intakeForm/:formToken'}
            component={IntakeFormQA}
          />
          <PrivateRoute
            exact
            path={'/account/profile'}
            component={ProfilePage}
          />
          <PrivateRoute
            exact
            path={'/account/information'}
            component={AccountInformation}
          />
          {user?.isActive && (
            <PrivateRoute
              exact
              path={'/account/billing'}
              component={BillingPage}
            />
          )}
          <PrivateRoute
            exact
            path={'/account/connect-with-calendar'}
            component={ConnectWithCalendar}
          />
          {
            <PrivateRoute
              exact
              path={'/callback'}
              component={CalendarCallback}
            />
          }
          <PrivateRoute exact path={'/capacity'} component={Capacity} />

          <PrivateRoute
            exact
            path={'/account/change-password'}
            component={ChangePasswordPage}
          />
          <PrivateRoute
            exact
            path={'/payments'}
            component={() => {
              return (
                <>
                  <Header />
                  <div className="mt-5">
                    <Payouts />
                  </div>
                </>
              );
            }}
          />

          {<PrivateRoute exact path={'/time-logs'} component={TimeLogs} />}
          {user?.isActive && (
            <PrivateRoute
              exact
              path={'/time-log/add'}
              component={NewTimeLogPage}
            />
          )}
          {
            <PrivateRoute
              exact
              path={'/time-log/:id/edit/:type'}
              component={NewTimeLogPage}
            />
          }

          <PrivateRoute
            exact
            path={[`/chat`, `/chat/:sdUrl`, `/chat/:sdUrl/:sendBirdId`]}
            component={Chat}
          />

          <PrivateRoute exact path={'/calendar'} component={TimeSlots} />
          <PrivateRoute
            exact
            path={'/extracurricular-opportunities'}
            component={SubmitSummerOppotunity}
          />
          <Redirect
            exact
            path={'/summer-opportunities'}
            to={`/extracurricular-opportunities`}
          />

          <PrivateRoute exact path={'/meetings'} component={Meetings} />
          <PublicRoute
            exact
            path={`/extracurricular-opportunities/find`}
            component={FindOpportunity}
          />
          <Redirect
            exact
            path={'/summer-opportunities/find'}
            to={`/extracurricular-opportunities/find`}
          />

          <Route exact path={'/maintenance'} component={Maintenance} />
          <Route component={Error404} />
        </Switch>
      </MainLayout>
    </Router>
  );
}

const mapSendbirdStateToProps = (state: any) => {
  return {
    state,
  };
};

export default withSendBird(Pages, mapSendbirdStateToProps);
