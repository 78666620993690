// imports
import { useState, FC } from 'react';
import { Box } from '@material-ui/core';
// components
import AppContainer from './AppContainer';
import ChatBotDialogWidget from './components/ChatBotDialogWidget';
// contexts, GraphQL
import client from './apollo';
import { ApolloProvider } from 'react-apollo';
import { User, AppContext, AppContextProps } from './contexts';
import { advisorLead } from './graphql/queries/advisorLead/__generated__/advisorLead';

declare global {
  interface Window {
    Intercom: any;
    nylas: any;
    dfMessenger: any;
  }

  namespace JSX {
    interface IntrinsicElements {
      'df-messenger': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
      'df-messenger-chat': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

const App: FC = () => {
  const [appContext, setAppContext] = useState<AppContextProps>({
    user: null,
    isLoggedIn: false,
    unreadMessagesCount: 0,
    setUser(user: User) {
      setAppContext({
        ...appContext,
        user,
      });
    },
    setIsLoggedIn(isLoggedIn: boolean) {
      setAppContext({
        ...appContext,
        isLoggedIn,
      });
    },
    setUnreadMessagesCount(data: any) {
      setAppContext({
        ...appContext,
        ...data,
      });
    },
    isCollegeUpdated: false,
    setIsCollegeUpdated(isCollegeUpdated: boolean) {
      setAppContext({
        ...appContext,
        isCollegeUpdated,
      });
    },
    advisorLeadData: null,
    setAdvisorLeadData(advisorLeadData: advisorLead) {
      setAppContext((prevContext) => ({
        ...prevContext,
        advisorLeadData,
      }));
    },
  });

  // Extract user email if user is logged in
  const userEmail = appContext.user?.email;

  return (
    <AppContext.Provider value={appContext}>
      <ApolloProvider client={client}>
        <AppContainer />
      </ApolloProvider>
    </AppContext.Provider>
  );
};

export default App;
