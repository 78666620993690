import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// assets
import Fonts from '../../common/fonts';
import fonts from '../../common/fonts';
import Colors from '../../common/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentHeightVh: {
      minHeight: 'calc(100vh - 97.36px)',

      "& [class*='MuiTypography-h3']": {
        fontSize: 24,
        fontWeight: 600,
        letterSpacing: '-0.456px',
        color: Colors.BLACK_TWELVE,
        fontFamily: fonts.POPPINS_FONT,
      },

      "& [class*='MuiTypography-body1']": {
        fontSize: 16,
        fontWeight: 400,
        marginBottom: 31,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.176px',
        color: Colors.GRAY_TWENTYTWO,
      },

      '& .betaBadge': {
        fontSize: 12,
        marginLeft: 13,
        borderRadius: 4,
        fontWeight: 600,
        cursor: 'pointer',
        padding: '0px 4px',
        color: Colors.WHITE_ONE,
        fontFamily: fonts.INTER,
        background: Colors.BLUE_THIRTEEN,
      },

      '& .submitButton': {
        minWidth: 84,
        fontSize: 14,
        fontWeight: 700,
        borderRadius: 12,
        padding: '12px 16px',
        letterSpacing: '-0.084px',

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
        },
      },

      '& input': {
        width: 320,
        fontSize: 14,
        marginRight: 8,
        borderRadius: 6,
        fontWeight: 400,
        padding: '8px 16px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.084px',
        color: Colors.BLACK_TWELVE,

        '&::placeholder': {
          color: Colors.GRAY_TWENTYTWO,
        },
      },

      '& .helperTextError': {
        marginTop: 4,
        fontSize: 12,
        fontWeight: 400,
        color: Colors.RED_SEVEN,
      },
    },

    contentBoxHeight: {
      minHeight: 'calc(100vh - 132px)',
      background: `${Colors.WHITE_FIVE}`,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    formContainer: {
      padding: '0px 8px',
    },

    card: {
      maxWidth: 400,
      padding: '24px',
      margin: '0px auto',
      marginBottom: '20px',
      boxShadow: `${Colors.BOX_SHADOW_FOUR}`,
    },

    signButton: {
      padding: '9px 33px',
      fontSize: '16px',
      fontWeight: 700,
      background: `${Colors.BLUE_SEVEN}`,
      fontFamily: `${Fonts.POPPINS_FONT}`,
      color: `${Colors.WHITE_ONE}`,
      width: '100%',
      marginTop: '10px',
      borderRadius: '0px',
      boxShadow: 'none',

      '&:focus': {
        outline: '0px',
      },

      '&:hover': {
        background: `${Colors.BLUE_SEVEN}`,
        opacity: 0.8,
      },
    },

    signNavigateButton: {
      width: 'auto',
      borderRadius: '3px',
      fontSize: '16px',
      background: `${Colors.WHITE_ONE}`,
      fontFamily: `${Fonts.POPPINS_FONT}`,
      color: `${Colors.BLUE_SEVEN}`,
      border: `2px solid ${Colors.BLUE_SEVEN}`,

      '&:focus': {
        outline: '0px',
      },

      '&:hover': {
        background: `${Colors.WHITE_ONE}`,
        opacity: 0.8,
      },
    },

    textfield: {
      marginBottom: theme.spacing(2),
      fontFamily: `${Fonts.POPPINS_FONT}`,

      '& .MuiInputBase-root': {
        fontFamily: `${Fonts.POPPINS_FONT}`,
      },

      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: `${Fonts.POPPINS_FONT}`,
      },

      '& .MuiInputLabel-shrink': {
        fontFamily: `${Fonts.POPPINS_FONT}`,
        color: `${Colors.BLUE_SEVEN}`,
      },

      '& .MuiInputLabel-animated': {
        fontFamily: `${Fonts.POPPINS_FONT}`,
      },

      '& label.Mui-focused': {
        color: `${Colors.BLUE_SEVEN}`,
      },

      '& .MuiInput-underline:after': {
        borderBottomColor: `${Colors.BLUE_SEVEN}`,
      },

      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${Colors.BLUE_SEVEN}`,
        },
        '&:hover fieldset': {
          borderColor: `${Colors.BLUE_SEVEN}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${Colors.BLUE_SEVEN}`,
        },
      },
    },

    linkUnderline: {
      textDecoration: 'underline',
    },

    FormBottomSection: {
      marginBottom: '15px',
      textAlign: 'center',
    },

    FormBottomText: {
      fontSize: '14px',
      fontFamily: `${Fonts.POPPINS_FONT}`,
      color: `${Colors.BLACK_THREE}`,
      margin: '20px 0px 0px',
    },

    FormBottomLink: {
      margin: '20px 0px 0px',
      transition: 'opacity 0.2s ease',

      '&:hover': {
        opacity: 0.7,
        textDecoration: 'none',
      },
    },

    formHeading: {
      fontSize: '17px',
      fontWeight: 500,
      color: `${Colors.BLACK_THREE}`,
      fontFamily: `${Fonts.POPPINS_FONT}`,
    },
  })
);
