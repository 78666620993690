import gql from 'graphql-tag';

export const SAVE_DF_MESSENGER_SESSION = gql`
  mutation saveDfMessengerSession(
    $email: String!
    $sessionId: String!
    $botType: dfMessengerBotType!
  ) {
    saveDfMessengerSession(
      input: { email: $email, sessionId: $sessionId, botType: $botType }
    ) {
      message
      success
    }
  }
`;
