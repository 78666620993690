
import { Box, Card, Typography, Button, Grid } from '@material-ui/core';
// icons
import ERROR_ICON from '../img/error-ic.svg';
// styles
import { useStyles } from '../../src/pages/main/students/MyStudentsStyle';

const BannerMessage = () => {
  const classes = useStyles();

    return (
      <Grid>
        <Card className={classes.alertInfoCard}>
          <Box display="flex" className={classes.alertInfoBlock}>
            <Grid className={classes.alertInfoIcon}>
              <img src={ERROR_ICON} alt="token" />
            </Grid>
            <Grid className={classes.alertInfoText}>
              <Typography variant="h5">Student account balance is overdue</Typography>
              <Typography variant="body1">Booking advisor meetings and milestone teams will be temporarily disabled  until the past-due balance is paid. If you have any question, please contact us at <span className={classes.emailTo}>success@collegeadvisor.com</span></Typography>
            </Grid>
          </Box>
        </Card>
      </Grid>   
    );
};

export default BannerMessage;