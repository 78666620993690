import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import fonts from '../../../common/fonts';
import colors from '../../../common/colors';

import RADIO_CHECK_ICON from '../../../img/radio-checked-form.svg';
import RADIO_UNCHECK_ICON from '../../../img/radio-uncheck-form.svg';
import SELECT_ARROW_ICON from '../../../img/select-arrow-icon.svg';

export const useStylesAccount = makeStyles((theme: Theme) =>
  createStyles({
    phoneInput: {
      width: 384,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },

      '& .react-phone-number-input__icon': {
        border: '0px',
      },

      '& .PhoneInput': {
        borderRadius: 6,
        padding: '10px 16px',
        border: '1px solid #D4D4D4',
      },

      '& .PhoneInputInput': {
        padding: 0,
        border: 'none',
        marginTop: -11,
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        paddingTop: 9,

        '&:focus': {
          borderColor: colors.BLUE_SEVEN,
        },

        '&.react-phone-number-input__input--disabled': {
          opacity: 0.4,
        },
      },

      '& .MuiFormHelperText-root.Mui-error': {
        margin: '5px 0px 0px',
        color: colors.RED_ONE,
        lineHeight: '12px',
        fontFamily: fonts.INTER,
        fontSize: 12,

        '&:after': {
          borderBottomColor: colors.RED_ONE,
        },
      },

      '& .Mui-error + div > input': {
        color: colors.RED_ONE,
      },

      '& .react-phone-number-input__error': {
        marginLeft: `calc(1.9em + 5px + 0.3em + 0.35em + 0.5em)`,
        marginTop: '5px',
        color: colors.RED_TWO,
        fontSize: '0.75em',
        fontWeight: 400,
      },

      '& .react-phone-number-input__input--invalid': {
        borderColor: colors.RED_TWO,
      },

      '& .react-phone-number-input__input--invalid::placeholder': {
        color: `!important ${colors.RED_TWO}`,
      },
    },

    asyncSelect: {
      '& .react-select-container': {
        '& .react-select__control': {
          borderRadius: 6,
          borderColor: colors.WHITE_SEVEN,
        },

        '& .react-select__placeholder': {
          fontSize: 14,
          fontWeight: 400,
          color: '#A9A9A9',
          fontFamily: fonts.INTER,
          letterSpacing: '-0.084px',
        },

        '& .react-select__multi-value': {
          borderRadius: 6,
          background: `${colors.BLUE_THIRTEEN} !important`,
        },

        '& .react-select__indicator-separator, & .react-select__indicator': {
          display: 'none',
        },

        '& .react-select__multi-value__remove': {
          '& svg': {
            width: 16,
            height: 16,
            opacity: 0.5,
            fill: colors.WHITE_ONE,
          },

          '&:hover': {
            borderRadius: '0px 6px 6px 0px',
            background: `${colors.BLUE_THIRTEEN} !important`,
          },
        },

        '& .react-select__multi-value__label': {
          fontSize: 14,
          fontWeight: 600,
          padding: '3px 6px',
          color: colors.WHITE_ONE,
          fontFamily: fonts.INTER,
          margin: '0px 4px 0px 0px',
          letterSpacing: '-0.084px',
        },

        '& .react-select__dropdown-indicator': {
          display: 'block !important',
        },

        '& .react-select__value-container': {
          padding: '0px 8px',
        },
      },
    },

    copyBookingLinkDark: {
      '&.MuiButton-root': {
        minWidth: 216,
        height: 40,
        backgroundColor: '#1E5298',
        borderRadius: 12,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        padding: '8px 15px',
        boxShadow: 'none',
        fontFamily: fonts.POPPINS_FONT,
        opacity: 1,

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },

        '& .MuiButton-label': {
          fontWeight: 700,
          fontSize: 14,
        },

        '& img': {
          marginRight: 11,
        },
      },
    },

    copyBookingLink: {
      '&.MuiButton-root': {
        minWidth: 216,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        textTransform: 'capitalize',
        padding: '8px 15px',
        boxShadow: 'none',
        fontFamily: fonts.POPPINS_FONT,

        [theme.breakpoints.down('xs')]: {
          marginTop: 20,
        },

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },

        '& .MuiButton-label': {
          fontWeight: 700,
          fontSize: 14,
        },

        '& img': {
          marginRight: 11,
        },
      },
    },

    accountInfoCard: {
      background: '#FFFFFF',
      borderRadius: 15,
      overflow: 'visible',
      border: '1px solid #D4D4D4',
      boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

      '& .infoCardHeaderProfile': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',

        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },

        '& a': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#2F80ED',
          fontFamily: fonts.INTER,
          borderBottom: '1px solid #2F80ED',
          lineHeight: '17px',
          minHeight: 21,
        },
      },

      '& .infoCardHeader': {
        padding: '23px 25px 19px',
        borderBottom: '1px solid #D4D4D4',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },

        '& .MuiTypography-h5': {
          fontWeight: 600,
          fontSize: 24,
          letterSpacing: '-0.019em',
          color: '#272929',
          fontFamily: fonts.POPPINS_FONT,
        },

        '& .MuiTypography-body1': {
          fontWeight: 400,
          fontSize: 14,
          color: '#7D7F7F',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          marginTop: 6,
        },
      },

      '& .accountInfoBody': {
        padding: '30px 25px 45px',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px',
        },

        '& .conflictsText': {
          fontWeight: 400,
          fontSize: 14,
          color: '#7D7F7F',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
        },

        '& .educationHeading': {
          fontWeight: 600,
          fontSize: 18,
          letterSpacing: '-0.014em',
          color: '#272929',
          fontFamily: fonts.INTER,
        },

        '& .educationText': {
          fontFamily: fonts.INTER,
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
        },
      },

      '& .badgeRequired': {
        minWidth: 74,
        height: 19,
        background: '#45CE93',
        borderRadius: 4,
        marginLeft: 25,
        fontFamily: fonts.INTER,
        fontWeight: 600,
        fontSize: 12,
        color: '#EDFBF5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.down('xs')]: {
          margin: '10px 0px 0px 0px',
        },
      },
    },

    actionButtons: {
      margin: '0px 0px 30px',
      border: '1px solid #D4D4D4',
      borderRadius: '15px',
      padding: '20px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    actionButtonsFile: {
      margin: '30px 0',
      border: '1px solid #2F80ED',
      borderRadius: '15px',
      padding: '20px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    profileLogo: {
      width: 62,
      height: 62,
      backgroundColor: '#e1e1e1',
      borderRadius: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      marginRight: 24,

      '& img': {
        objectFit: 'contain !important',
        objectPosition: 'center !important',
      },

      '@media only screen and (max-width: 767px)': {
        marginRight: 16,
      },
    },

    accountInformationForm: {
      '& .mobileFieldSpacing': {
        [theme.breakpoints.down('xs')]: {
          marginBottom: '18px !important',
        },
      },

      '& .fieldCaption': {
        marginBottom: 4,
        fontFamily: fonts.INTER,
        fontWeight: 400,
        fontSize: '14px !important',
        letterSpacing: '-0.006em',
        color: `${colors.BLACK_TWELVE} !important`,
      },

      '& .react-select__single-value': {
        width: '95%',
      },

      '& .mobileFlex': {
        [theme.breakpoints.down('xs')]: {
          alignItems: 'flex-start',
          flexDirection: 'column',
          margin: 0,
        },

        '& .MuiInputBase-root, & .MuiBox-root': {
          [theme.breakpoints.down('xs')]: {
            width: '100%',
          },
        },
      },

      '& .editButton': {
        marginLeft: 15,
      },

      '& .infoCardHeaderChild': {
        padding: '10px 0px',
        marginBottom: 20,
      },

      '& .textFieldCaptionDialog': {
        margin: 0,
      },

      '& .textFieldSubtitleDialog': {
        fontWeight: 400,
        fontSize: 12,
        color: '#A9A9A9',
        marginBottom: 6,
      },

      '& .calendarFields': {
        marginRight: 20,

        '& .MuiInputBase-root': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiInputBase-input': {
          padding: 0,
        },
      },

      '& .zoomLink': {
        maxWidth: 384,
        width: '100%',
        marginRight: 25,

        '& .MuiInputBase-inputMultiline': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiFormControl-root': {
          width: '100%',
        },
      },

      '& .MuiInputBase-multiline': {
        padding: 0,
      },

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,

        '& .MuiFormControlLabel-label': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.011em',
          color: '#7D7F7F',
          fontFamily: fonts.INTER,
        },
      },

      '& .MuiCheckbox-colorPrimary': {
        color: '#7D7F7F',
      },

      '& .acceptedToDropdown': {
        width: '100% !important',

        '& .MuiMenuItem-root': {
          fontSize: 14,
          whiteSpace: 'break-spaces',
        },

        '& .MuiInputBase-root': {
          padding: '6px 35px 6px 11px',
          maxHeight: '200px !important',
          height: 'auto',
          display: 'flex',
          alignItems: 'baseline',

          [`@media only screen and (max-height: 500px)`]: {
            height: 110,
          },
        },
      },

      '& .acceptedToDropdown.maxHeight': {
        '& .MuiInputBase-root': {
          maxHeight: 'unset !important',
        },
      },

      '& .timeZoneSelect': {
        width: 521,

        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& .MuiInputBase-root': {
          width: '100%',
        },

        '& .react-select__indicator-separator, & .react-select__indicator': {
          display: 'none',
        },
      },

      '& .genderDropdownIcon': {
        '& .MuiSvgIcon-root': {
          display: 'none',
        },
      },

      '& .genderDropdown': {
        marginRight: 20,

        '& .MuiInputBase-root': {
          width: 113,
          borderRadius: 6,
          padding: '0px 16px',
          border: '1px solid #D4D4D4',
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          margin: 0,
          height: 41,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 16px',
          backgroundImage: `url(${SELECT_ARROW_ICON})`,
        },

        '& .MuiChip-deleteIcon': {
          width: 16,
          color: '#96BFF5',
        },

        '& .MuiSelect-select:focus': {
          backgroundColor: 'transparent',
        },
      },

      '& .fieldsBoxSpacing': {
        marginBottom: 18,
      },

      '& .captionSpacing': {
        margin: '0px !important',
      },

      '& .captionMargin': {
        marginBottom: 4,
      },

      '& .cautionIcon': {
        padding: 0,
        marginLeft: 15,
        width: 15,
      },

      '& .nameField': {
        width: 182,
        marginRight: 20,

        [theme.breakpoints.down('xs')]: {
          width: '100%',
          margin: '0px 0px 10px',
        },
      },

      '& .emailField': {
        width: 384,
        marginRight: 25,

        [theme.breakpoints.down('xs')]: {
          marginRight: 0,
        },

        [theme.breakpoints.down('md')]: {
          width: '95%',
        },
      },

      '& .infoText': {
        fontSize: 12,
        fontWeight: 500,
        fontFamily: fonts.POPPINS_FONT,
        color: colors.BLUE_SEVEN,
      },

      '& .textFieldCaption': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#272929',
        fontFamily: fonts.INTER,
        marginBottom: 4,
      },

      '& .styleTextField': {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },

        '& .MuiInputBase-root': {
          fontWeight: 400,
          fontSize: 14,
          color: '#272929',
          letterSpacing: '-0.006em',
          fontFamily: fonts.INTER,
          borderRadius: 6,
          padding: '11px 16px',
          border: '1px solid #D4D4D4',
        },

        '& .MuiInputBase-input': {
          padding: 0,
        },
      },

      '& .MuiInput-underline::after, & .MuiInput-underline::before': {
        display: 'none',
      },

      '& .saveChanges': {
        minWidth: 135,
        height: 40,
        background: '#2F80ED',
        borderRadius: 12,
        fontWeight: 700,
        fontSize: 14,
        color: '#ffffff',
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,
        marginTop: 30,
        padding: '0px 10px',

        '&:hover': {
          backgroundColor: '#2668C1 !important',
        },
      },
    },

    advisedCollegesDialog: {
      "& [class*='MuiDialog-paper']": {
        overflowY: 'visible',
        maxWidth: '812px !important',

        "& [class*='MuiTypography-h4']": {
          marginBottom: '6px !important',
        },

        "& [class*='MuiDialogContent-root']": {
          overflowY: 'visible',

          '&:first-child': {
            paddingBottom: 20,
          },
        },
      },

      "& [class*='MuiTypography-body2']": {
        fontSize: 14,
        fontWeight: 400,
        color: '#A9A9A9',
        marginBottom: 21,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
      },

      '& .experienceField': {
        "& [class*='MuiFormControl-root']": {
          width: '100%',
        },
      },
    },

    experienceDialog: {
      '& .MuiDialog-paper': {
        maxWidth: '812px !important',

        '& .MuiTypography-h4': {
          marginBottom: '6px !important',
        },
      },

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        marginBottom: 21,
        fontFamily: fonts.INTER,
      },

      '& .experienceField': {
        '& .MuiFormControl-root': {
          width: '100%',
        },
      },
    },

    undergraduateEducation: {
      '& .MuiDialog-paper': {
        overflow: 'visible !important',
        maxWidth: '661px !important',

        '& .MuiInputBase-root': {
          width: '100% !important',
          height: '40px !important',
        },
      },

      '& .MuiTypography-h4': {
        marginBottom: '20px !important',
      },

      '& .MuiDialogContent-root': {
        padding: '40px 0px 46px !important',

        [theme.breakpoints.down('xs')]: {
          padding: '20px 16px 30px !important',
        },

        [`@media only screen and (max-height: 800px)`]: {
          padding: '20px 16px !important',
        },
      },

      '& .fieldsBoxSpacing': {
        marginBottom: '4px !important',
      },

      '& .textFieldSubtitleDialog': {
        margin: '4px 0px 0px !important',
      },

      '& .react-select__control--is-disabled': {
        opacity: 0.7,
        backgroundColor: '#F4F4F4 !important',
        border: '1px solid #EAEAEA !important',
      },

      '& .react-select__option--is-focused': {
        background: `#ebebeb !important`,
      },

      '& .sectionDivider': {
        background: '#D4D4D4',
        margin: '15px 0px 10px',
      },

      '& .dialogHeader': {
        padding: '0px 43px',

        [theme.breakpoints.down('xs')]: {
          padding: '0px !important',
        },
      },

      '& .MuiButton-root': {
        padding: '8px !important',
      },

      '& .deleteButton': {
        top: 10,
        minWidth: 40,
        position: 'relative',

        [theme.breakpoints.down('xs')]: {
          top: 10,
        },
      },

      '& .MuiButton-contained.Mui-disabled': {
        opacity: 0.5,
        color: '#fff !important',
        background: '#2f80ed !important',
        border: '1px solid #2f80ed !important',
      },
    },

    educationDialog: {
      '& .MuiDialog-paper': {
        maxWidth: 551,
        width: '100%',
        borderRadius: 25,
        margin: '0px 10px',
        boxShadow: '0px 4px 14px rgba(68, 68, 68, 0.14)',

        [theme.breakpoints.down('xs')]: {
          margin: '0px 10px !important',
          borderRadius: 10,
        },

        '& .styleTextFieldDialog': {
          '& .MuiInputBase-root, & .MuiFormControl-root': {
            width: '100%',
          },
        },

        '& .gradYear': {
          '& .MuiInputBase-root': {
            width: 121,
          },
        },

        '& .experienceHeading': {
          marginBottom: '21px !important',
        },

        '& .MuiTypography-h4': {
          fontFamily: fonts.POPPINS_FONT,
          fontWeight: 600,
          fontSize: 24,
          letterSpacing: '-0.019em',
          color: '#000000',
          marginBottom: 10,

          [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            lineHeight: 'initial',
          },
        },

        '& .MuiButton-root': {
          minWidth: 134,
          height: 40,
          borderRadius: 12,
          fontWeight: 600,
          fontSize: 14,
          letterSpacing: '-0.006em',
          textTransform: 'capitalize',
          boxShadow: 'none',
          fontFamily: fonts.POPPINS_FONT,
        },

        '& .MuiButton-outlined': {
          marginRight: 14,
          color: '#272929',
          border: '1px solid #D4D4D4',
        },

        '& .MuiButton-contained': {
          color: colors.WHITE_ONE,
          background: colors.BLUE_SEVEN,
          border: '1px solid #2F80ED',

          '& .MuiButton-label': {
            fontSize: 14,
          },

          '&.Mui-disabled': {
            cursor: 'not-allowed',
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            border: '1px solid rgba(0, 0, 0, 0.12)',
          },

          [theme.breakpoints.down('xs')]: {
            minWidth: 124,
          },
        },

        '& .dialogAction': {
          padding: '20px 34px 0px',
        },
      },
    },

    educationInformation: {
      '& .MuiDivider-root': {
        margin: '25px 0px 31px',
        backgroundColor: '#d4d4d4',
      },

      '& .headingSpacing': {
        marginBottom: 16,
      },

      '& .educationHeading': {
        fontWeight: 600,
        fontSize: 18,
        letterSpacing: '-0.014em',
        color: '#272929',
        fontFamily: fonts.INTER,
      },

      '& .experienceText': {
        '& .MuiInputBase-input.Mui-disabled': {
          fontWeight: 400,
          fontSize: 14,
          letterSpacing: '-0.006em',
          color: '#525454',
          fontFamily: fonts.INTER,
        },
      },

      '& .experienceTextSpacing': {
        marginBottom: 20,
      },

      '& .graduateEducation': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#2F80ED',
        fontFamily: fonts.POPPINS_FONT,
        marginBottom: 20,

        '& img': {
          marginRight: 15,
        },
      },

      '& .tagsWrapper': {
        display: 'flex',
        flexWrap: 'wrap',
      },

      '& .disabledState': {
        color: '#A9A9A9',
      },

      '& .intlExperienceMessage': {
        color: '#A9A9A9',
        marginTop: 25,
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '-0.006em',
      },

      '& .tagsBadge': {
        minHeight: 32,
        borderRadius: 6,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#FFFFFF',
        fontFamily: fonts.INTER,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 12px',
        margin: '0px 12px 10px 0px',
      },
    },

    // CSS for the radio button of previously worked theme change
    previouslyWorkedThemeRadioButton: {
      display: 'flex',

      '& .MuiFormControlLabel-root': {
        marginBottom: 0,

        '& .MuiIconButton-root': {
          width: 20,
          height: 20,
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${RADIO_UNCHECK_ICON})`,

          '&.Mui-checked': {
            backgroundImage: `url(${RADIO_CHECK_ICON})`,
          },

          '& .MuiSvgIcon-root': {
            display: 'none',
          },
        },
      },
    },

    educationTable: {
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: '1px solid #D4D4D4',
      padding: '12px 24px',
      marginBottom: 40,

      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },

      '& .tableCellText': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#525454',
        fontFamily: fonts.INTER,
      },

      '& .tableCellTextRadio': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 15,
        },
      },

      '& .educationFirstCell': {
        width: '35%',
        paddingRight: 15,
        textTransform: 'capitalize',

        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },

      '& .educationSecondCell': {
        width: '25%',

        [theme.breakpoints.down('sm')]: {
          width: '80%',
        },
      },

      '& .educationThirdCell': {
        width: '10%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          width: '20%',
          textAlign: 'right',
          paddingRight: 5,
        },

        '& .emptyTextSpan': {
          [theme.breakpoints.down('sm')]: {
            padding: '0px 9px 0px 12px',
          },
        },
      },

      '& .educationFourCell': {
        width: '10%',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
          width: '70%',
          display: 'flex',
          padding: '10px 0px',
        },

        '& .MuiFormControlLabel-labelPlacementTop': {
          margin: 0,
        },

        '& .MuiFormControlLabel-root': {
          marginBottom: 0,

          '& .MuiIconButton-root': {
            width: 20,
            height: 20,
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${RADIO_UNCHECK_ICON})`,

            '&.Mui-checked': {
              backgroundImage: `url(${RADIO_CHECK_ICON})`,
            },

            '& .MuiSvgIcon-root': {
              display: 'none',
            },
          },
        },
      },

      '& .educationFiveCell': {
        width: '5%',

        [theme.breakpoints.down('sm')]: {
          width: '30%',
          textAlign: 'right',
        },

        '& .MuiIconButton-root': {
          minWidth: 40,
        },
      },
    },

    educationTableHeader: {
      border: 0,
      margin: 0,
      padding: '0px 24px 9px',

      '& .tableCellText': {
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: '0.006em',
        textTransform: 'uppercase',
        color: '#7D7F7F',
        fontFamily: fonts.POPPINS_FONT,
      },
    },

    connectStripe: {
      '&.MuiButton-root': {
        fontWeight: 600,
        background: colors.BLUE_SEVEN,
        fontFamily: fonts.POPPINS_FONT,
        color: colors.WHITE_ONE,
        textTransform: 'capitalize',
        borderRadius: 12,
        boxShadow: 'none',
        textAlign: 'center',
        fontSize: 14,
        letterSpacing: '-0.006em',
        minWidth: 190,
        padding: '4px 15px',
        height: 40,

        '& svg': {
          marginRight: 15,
        },

        '&:hover': {
          background: '#2668C1 !important',
        },
      },
    },

    confirmationDialog: {
      '& .MuiDialog-paper': {
        margin: 10,
        width: 488,
        maxWidth: 488,
        borderRadius: 25,
        overflowY: 'visible',
        padding: '35px 34px 31px',
        border: `1px solid ${colors.WHITE_SEVEN}`,

        [theme.breakpoints.down('xs')]: {
          padding: 16,
        },
      },

      '& .spinner-border-sm': {
        fontSize: 10,
      },

      '& .MuiTypography-h4': {
        maxWidth: 406,
        color: colors.BLACK_TWELVE,
        fontSize: 24,
        fontFamily: fonts.POPPINS_FONT,
        fontWeight: 600,
        letterSpacing: '-0.019em',
        lineHeight: '32px',
        marginBottom: 4,
      },

      '& .MuiTypography-body1': {
        fontSize: 14,
        fontWeight: 400,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.GRAY_CHECKBOX_BG,
      },

      '& .MuiButton-root': {
        height: 40,
        padding: 8,
        fontSize: 14,
        fontWeight: 600,
        borderRadius: 12,
        letterSpacing: '-0.006em',
        textTransform: 'capitalize',
        fontFamily: fonts.POPPINS_FONT,

        '&:hover': {
          boxShadow: 'none',
        },
      },

      '& .MuiButton-root.MuiButton-containedPrimary': {
        minWidth: 159,
        backgroundColor: `${colors.RED_BUTTON_COLOR} !important`,

        '&:hover, &:active': {
          boxShadow: 'none',
          backgroundColor: `${colors.RED_DARK_HOVER} !important`,
        },

        '&.Mui-disabled': {
          opacity: 0.5,
          cursor: 'not-allowed !important',
        },

        [theme.breakpoints.down('xs')]: {
          minWidth: 120,
        },
      },

      '& .MuiButton-outlined': {
        minWidth: 105,
        marginRight: 11,
        color: colors.BLACK_TWELVE,
        border: `1px solid ${colors.WHITE_SEVEN}`,

        [theme.breakpoints.down('xs')]: {
          minWidth: 140,
        },
      },
    },

    addReasonModel: {
      '& .MuiButton-root.MuiButton-containedPrimary': {
        minWidth: 159,
        color: colors.WHITE_ONE,
        backgroundColor: `${colors.BLUE_SEVEN} !important`,
        marginRight: 0,
        [theme.breakpoints.down('xs')]: {
          minWidth: 140,
        },
      },
      '& .MuiButton-root.MuiButton-containedPrimary:hover': {
        background: `${colors.BLUE_SEVEN} !important`,
      },
      '& .MuiButton-contained:hover': {
        boxShadow: 'none',
      },
      '& .MuiButton-outlined': {
        minWidth: 151,
        marginRight: 16,
        backgroundColor: `${colors.WHITE_ONE} !important`,
        color: `${colors.BLACK_TWELVE} !important`,
        border: `1px solid ${colors.WHITE_SEVEN} !important`,
        [theme.breakpoints.down('xs')]: {
          minWidth: 120,
        },
      },
    },
    meetTime: {
      color: `${colors.GRAY_CHECKBOX_BG} !important`,
    },
    muiPaperOverflowY: {
      '& .MuiDialog-paper': {
        overflowY: 'unset',
      },
    },
    advisorSelectDropDown: {
      '& .react-select__control': {
        fontSize: 14,
        fontWeight: 400,
        borderRadius: 6,
        padding: '3px 16px',
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        color: colors.BLACK_TWELVE,
        backgroundRepeat: 'no-repeat',
        borderColor: colors.WHITE_SEVEN,
        backgroundPosition: 'center right 16px',
        backgroundImage: `url(${SELECT_ARROW_ICON})`,
        boxShadow: 'none',

        '&:hover': {
          borderColor: colors.WHITE_SEVEN,
        },
      },

      '& .react-select__control--is-focused': {
        borderColor: colors.WHITE_SEVEN,
      },

      '& .react-select__indicator, & .react-select__indicator-separator': {
        display: 'none',
      },

      '& .react-select__placeholder': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        fontFamily: fonts.INTER,
        color: colors.GRAY_TWENTYTWO,
      },

      '& .react-select__value-container': {
        padding: 0,
      },

      '& .react-select__menu': {
        margin: 0,
        zIndex: 999999,
        borderRadius: '0px 0px 6px 6px',
        backgroundColor: colors.WHITE_ONE,
      },

      '& .react-select__option': {
        fontSize: 14,
        fontFamily: fonts.INTER,
        letterSpacing: '-0.006em',
        paddingLeft: 19,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontWeight: 'normal',
        textOverflow: 'ellipsis',
        color: colors.GRAY_TAB_BORDER,
        backgroundColor: 'transparent',

        '&--is-focused': {
          backgroundColor: colors.SELECT_OPTION_ACTIVE,
        },

        '&:hover': {
          cursor: 'pointer',
          background: colors.GRAY_BOREDER,
        },
      },
    },
    popOverModel: {
      '& .MuiPopover-paper': {
        overflowX: 'unset',
        overflowY: 'unset',
        width: 488,
        borderRadius: 20,
        padding: '30px 34px',
        border: `1px solid ${colors.WHITE_SEVEN}`,
        boxShadow: `0px 4px 14px ${colors.RGBA_COLOR_ONE}`,
      },
    },

    popOverBoxListing: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },

      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#7D7F7F',
      },

      '& .MuiTypography-h5': {
        fontWeight: 600,
        letterSpacing: '-0.019em',
        textTransform: 'capitalize',
        color: '#272929',
        fontFamily: fonts.POPPINS_FONT,
        marginBottom: 5,

        '& img': {
          marginRight: 10,
        },
      },

      '& .MuiDivider-root': {
        margin: '19px 0px 10px',
      },

      '& .MuiListItem-root': {
        padding: 0,
        marginBottom: 14,

        '&:last-child': {
          marginBottom: 0,
        },
      },

      '& .MuiListItemText-root': {
        [theme.breakpoints.down('xs')]: {
          width: 100,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },

      '& .MuiList-root': {
        paddingBottom: 0,
      },

      '& .MuiListItemAvatar-root': {
        minWidth: 30,
      },

      '& .MuiAvatar-root': {
        width: 19,
        height: 19,
        marginRight: 7,
      },

      '& .spinner-border-sm': {
        fontSize: 10,
      },
    },
    otherReason: {
      width: '100%',
      borderColor: colors.WHITE_SEVEN,
      borderRadius: '6px',
      padding: '6px 12px',
      marginBottom: 25,
      fontFamily: fonts.INTER,
      fontWeight: 400,
      fontSize: 14,
      color: colors.BLACK_TWELVE,

      '&::placeholder': {
        color: colors.GRAY_TWENTYTWO,
      },

      '&:focus-visible': {
        outline: 'none',
      },
    },

    internationalStudent: {
      '& .MuiTypography-h4': {
        marginBottom: '6px !important',
      },

      '& .MuiTypography-body2': {
        fontWeight: 400,
        fontSize: 14,
        letterSpacing: '-0.006em',
        color: '#A9A9A9',
        marginBottom: 21,
        fontFamily: fonts.INTER,
      },

      '& svg': {
        display: 'none',
      },
    },
  })
);
