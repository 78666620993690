import gql from 'graphql-tag';

export const GET_SALES_TEAM_USER_QUERY = gql`
  query GetSalesTeamUserByTokenQuery($authKey: String!) {
    getSalesTeamUserByToken(authKey: $authKey) {
      email
      key
    }
  }
`;
