import { useState, ReactNode, FC, useContext } from 'react';
import clsx from 'clsx';
import Moment from 'moment';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
  Paper,
  Container,
  Tooltip,
  Box,
  IconButton,
  FormControl,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
  Hidden,
  ThemeProvider,
  LinearProgress,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';

import { Query } from 'react-apollo';
import {
  disableDropDown,
  isSubstring,
  selectColor,
} from '../../../common/utility';
import client from '../../../apollo';
import CampusesCheckBoxes from './Campuses';
import Loader from '../../../components/Loader';
import Header from '../../../components/layout/Header';
import FooterNew from '../../../components/layout/FooterNew';
import SupplementalEssays from './SupplementalEssaysTable';
import StudentApplicationTodos from './StudentApplicationTodos';
import {
  StudentApplicationDetailNewQuery,
  StudentApplicationDetailNewQuery_StudentApplication_studentApplicationEssays,
} from './graphql/queries/applicationsPage/__generated__/StudentApplicationDetailNewQuery';

import { useStyles } from './ApplicationPageStyles';
import { useStylesApplication } from './applicationStyle';
import { useStylesTable } from './tableCommonStyle';

import Colors from '../../../common/colors';
import Fonts from '../../../common/fonts';

import TrashIcon from '../../../img/trash-icon.svg';
import FILE_ICON from '../../../img/file.svg';
import DRIVE_ICON from '../../../img/drive.png';
import ADD_NEW_ICON from '../../../img/add-new.svg';
import DIALOG_CLOSE_ICON from '../../../img/dialog-close.svg';
import BREADCRUMB_LINK_IMG from '../../../img/back-arrow-hub.svg';
import CollegeDefaultImg from '../../../img/college-default-img.svg';
import USER_ICON from '../../../img/empty-badge.svg';
import ChangeApplicationStatus from './ChangeApplicationStatus';
import { AppContext } from '../../../contexts';
import Toast from '../../../components/Toast';
import { customTheme } from '../../MaterializeCss';
import { MenuProps } from '../../../styles/common';
import moment from 'moment';
import AddIcon from '../../../img/add-icon.svg';
import {
  CustomTooltip,
  AdvisorIconTooltip,
} from '../../../common/CommonComponents';

import {
  DELETE_APPLICATION_DEADLINE,
  UPDATE_APPLICATION_STATUS_AND_DEADLINE,
  ADD_NEW_STUDENT_APPLICATION_DEADLINE,
  UPDATE_STUDENT_APPLICATION_STATUS,
  UPDATE_OTHER_ESSAY_STATUS,
  UPDATE_STUDENT_ESSAY_STATUS,
  SET_ASSIST_STATUS,
  SET_BSMD_STATUS,
  SET_ESSAY_COUNT,
} from './graphql/mutations/applicationsPage';

import {
  STUDENT_APPLICATION_QUERY,
  OTHER_ESSAY_QUERY,
  GET_BSMD_STATUS,
  GET_ASSIST_STATUS,
  GET_APP_COUNT_USED,
} from './graphql/queries/applicationsPage';
import AddNewApplicationDealine from './AddnewDeadlineModal';
import AddEditApplicationMajor from './AddEditApplicationMajor';
import { EditIcon } from './assets/svg';

const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: `${Colors.BLACK_THREE}`,
    boxShadow: `0px 2px 10px ${Colors.BOX_SHADOW_THREE}`,
    borderRadius: '3px',
    padding: '16px 20px 20px',
    fontFamily: `${Fonts.POPPINS_FONT}`,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.3375px',
    color: `${Colors.WHITE_ONE}`,
    minWidth: '300px',
    width: '300px',
  },
}));

const useMenuStyles = makeStyles((theme) => ({
  menuItemSimple: {
    fontWeight: 'normal',
    fontSize: 14,
    letterSpacing: '-0.006em',
    color: '#272929',
    fontFamily: Fonts.INTER,

    '& span': {
      lineHeight: '24px',
    },
  },
  menuItem: {
    fontWeight: 'normal',
    fontSize: 14,
    letterSpacing: '-0.006em',
    color: '#272929',
    fontFamily: Fonts.INTER,

    '& span': {
      lineHeight: '24px',
    },

    '& img': {
      marginLeft: 10,
    },

    '&:last-child': {
      color: '#2F80ED',
      fontWeight: 600,
      letterSpacing: '0.006em',
      textTransform: 'uppercase',
      fontFamily: Fonts.POPPINS_FONT,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center left 16px',
      backgroundSize: 10,
      backgroundImage: `url(${ADD_NEW_ICON})`,
      paddingLeft: 32,
    },
  },
}));

interface TabContainerProps {
  children?: ReactNode;
}
function TabContainer(props: TabContainerProps) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

type TParams = { id: string };

const ApplicationDetailPage: FC<RouteComponentProps<TParams>> = ({
  history,
  match: {
    params: { id },
  },
  match,
}) => {
  const { search } = useLocation();

  const campusId = Number(search.split('&')[1]?.split('=')[1]);

  const studentApplicationId = parseInt(id);
  const [openNewDeadlineModal, setOpenNewDeadlineModal] = useState(false);
  const [openCommonEssayModal, setOpenCommonEssayModal] = useState(false);
  const classes = useStyles();
  const applicationClasses = useStylesApplication();
  const tableClasses = useStylesTable();
  const tooltipClasses = useTooltipStyles();
  const menuClasses = useMenuStyles();
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AppContext);
  const advisorId = user?.id;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [deadlineUpdating, setDeadlineUpdating] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [studentId, setStudentId] = useState<number>(0);
  const [assistId, setAssistId] = useState<number>(0);
  const [bsmdId, setBsmdId] = useState<number>(0);
  const [isBSMDChecked, setIsBSMDChecked] = useState<boolean>(false);
  const [isAssistChecked, setIsAssistChecked] = useState<boolean>(false);
  const [addMajorDialog, setAddMajorDialog] = useState<boolean>(false);

  // mutation handler for BSMD
  const handleSetBsmdStatus = async (
    checked: boolean,
    id: number | undefined,
    refetchGetBsmdStatus: Function,
    studentId: number
  ) => {
    try {
      const response = await client.mutate({
        mutation: SET_BSMD_STATUS,
        variables: {
          id,
        },
      });

      // set toast on success
      if (response) {
        refetchGetBsmdStatus();
        setIsBSMDChecked(checked);
        setOpenSnackbar(true);
        setSnackbarMessage('BSMD Status Updated');
        const applicationCount: any = await client.query({
          query: GET_APP_COUNT_USED,
          variables: {
            studentId,
          },
          fetchPolicy: 'network-only',
        });
        let totalAppUsed: number =
          applicationCount?.data?.getApplicationCountUsed
            ?.entitlementCountUsed || 0;
        const totalAppUsedInDB: number =
          applicationCount?.data?.getApplicationCountUsed
            ?.entitlementCountUsed || 0;

        if (checked) {
          if (isAssistChecked) totalAppUsed++;
        } else {
          if (isAssistChecked) totalAppUsed--;
        }

        if (totalAppUsed !== totalAppUsedInDB) {
          const response = await client.mutate({
            mutation: SET_ESSAY_COUNT,
            variables: {
              studentId,
              appCreditUsed: totalAppUsed,
            },
          });
          console.log('db count bsmd', totalAppUsedInDB);
          console.log('updated count bsmd', totalAppUsed);
          console.log('essay count update call', response);
        }
      }
    } catch (error) {
      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  };

  const handleToggleMajorsDialog = () => {
    setAddMajorDialog((value) => !value);
  };

  // mutation handler for Assist
  const handleSetAssistStatus = async (
    checked: boolean,
    id: number | undefined,
    refetchGetAssistStatus: Function,
    studentId: number,
    campusFlag: boolean
  ) => {
    try {
      const response = await client.mutate({
        mutation: SET_ASSIST_STATUS,
        variables: {
          id,
        },
      });

      // set toast on success
      if (response) {
        refetchGetAssistStatus();
        setIsAssistChecked(checked);
        setOpenSnackbar(true);
        setSnackbarMessage('Application Status Updated');
        const applicationCount: any = await client.query({
          query: GET_APP_COUNT_USED,
          variables: {
            studentId,
          },
          fetchPolicy: 'network-only',
        });
        let totalAppUsed: number =
          applicationCount?.data?.getApplicationCountUsed
            ?.entitlementCountUsed || 0;
        const totalAppUsedInDB: number =
          applicationCount?.data?.getApplicationCountUsed
            ?.entitlementCountUsed || 0;

        if (checked) {
          totalAppUsed++;
          if (isBSMDChecked || campusFlag) totalAppUsed++;
        } else {
          totalAppUsed--;
          if (isBSMDChecked || campusFlag) totalAppUsed--;
        }

        const response = await client.mutate({
          mutation: SET_ESSAY_COUNT,
          variables: {
            studentId,
            appCreditUsed: totalAppUsed,
          },
        });
        console.log('db count bsmd', totalAppUsedInDB);
        console.log('updated count bsmd', totalAppUsed);
        console.log('essay count update call', response);
      }
    } catch (error) {
      // set loading state to false
      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  };

  const handleChangeEssayStatus = async (
    status: String,
    essayId: number,
    refetch: Function,
    essayName?: string
  ) => {
    const response = await client.mutate({
      mutation: UPDATE_STUDENT_ESSAY_STATUS,
      variables: {
        applicationEssayId: essayId,
        status,
        essayName: essayName,
      },
    });
    refetch();
    if (response) {
      setOpenSnackbar(true);
      setSnackbarMessage('Essay Status Updated');
    }
  };

  const handleChangeOtherEssayStatus = async (
    status: String,
    essayId: number,
    refetch: Function,
    essayName?: string,
    isOtherEssay?: boolean
  ) => {
    if (!isOtherEssay) {
      const otherEssayResponse = await client.mutate({
        mutation: UPDATE_OTHER_ESSAY_STATUS,
        variables: {
          essayId: essayId,
          status,
        },
      });
      refetch();
      if (otherEssayResponse) {
        setOpenSnackbar(true);
        setSnackbarMessage('Essay Status Updated');
      }
    } else {
      const studentEssayResponse = await client.mutate({
        mutation: UPDATE_STUDENT_ESSAY_STATUS,
        variables: {
          applicationEssayId: essayId,
          status,
          essayName: essayName,
        },
      });
      refetch();
      if (studentEssayResponse) {
        setOpenSnackbar(true);
        setSnackbarMessage('Essay Status Updated');
      }
    }
  };

  const handleChangeApplicationStatus = async (
    status: String,
    applicationId: number,
    refetch: Function
  ) => {
    const response = await client.mutate({
      mutation: UPDATE_STUDENT_APPLICATION_STATUS,
      variables: {
        studentApplicationId: applicationId,
        status,
      },
    });
    if (response) {
      setOpenSnackbar(true);
      setSnackbarMessage('Application Status Updated');
    }
    refetch();
  };

  const handleNotificationPopup = (message: string) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };
  const handleNotificationClose = () => {
    setOpenSnackbar(false);
    setSnackbarMessage('');
  };

  const handleAddNewDeadline = async (variables: any, refetch: Function) => {
    const { deadlineName, date } = variables;
    await client.mutate({
      mutation: ADD_NEW_STUDENT_APPLICATION_DEADLINE,
      variables: {
        studentApplicationId: studentApplicationId,
        deadlineName: deadlineName,
        deadlineDate: date,
      },
    });
    setOpenNewDeadlineModal(false);
    await refetch(date, deadlineName);
  };

  const [appDeadline, setAppDeadline] = useState('');

  function showCommonEssayModalData(
    commonEssays:
      | (StudentApplicationDetailNewQuery_StudentApplication_studentApplicationEssays | null)[]
      | null,
    refetch: Function,
    closeModal: Function
  ) {
    return (
      <Grid key={id}>
        <Hidden only={['sm', 'xs']}>
          <Table className={applicationClasses.dialogTableDocument}>
            <TableHead>
              <TableRow>
                <TableCell width="35%"></TableCell>
                <TableCell width="35%">CURRENT FILE</TableCell>
                <TableCell width="30%" align="right"></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {commonEssays &&
                commonEssays.length &&
                commonEssays.map((essay: any) => {
                  const { name, documentUrl, essayTemplate } = essay;
                  if (!essayTemplate) return null;
                  const Essayname = isSubstring(
                    essayTemplate?.shortDescription,
                    'common'
                  )
                    ? 'Common App Essays'
                    : 'Coalition App Essay';

                  return (
                    <TableRow>
                      <TableCell className="essayType">{Essayname}</TableCell>
                      <TableCell>
                        {/* {documentUrl ? name : '--'} */}
                        {documentUrl?.length ? (
                          <a
                            href={documentUrl || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {documentUrl?.includes('google') ? (
                              <>
                                <img
                                  src={DRIVE_ICON}
                                  alt="drive"
                                  style={{ width: '23px' }}
                                />
                                {name
                                  ? name.length > 25
                                    ? `${name.substring(0, 25)}...`
                                    : name
                                  : 'no name'}
                              </>
                            ) : (
                              <img src={FILE_ICON} alt="file" />
                            )}
                          </a>
                        ) : (
                          <Box component="span">--</Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Hidden>

        <Hidden only={['xl', 'lg', 'md']}>
          {commonEssays &&
            commonEssays.length &&
            commonEssays.map((essay: any) => {
              const { name, documentUrl, essayTemplate } = essay;
              if (!essayTemplate) return null;
              const Essayname = isSubstring(
                essayTemplate?.shortDescription,
                'common'
              )
                ? 'Common App Essays'
                : 'Coalition App Essay';

              return (
                <Grid className={applicationClasses.dialogTableDocumentMobile}>
                  <Grid className="essayType">{Essayname}</Grid>
                  <Grid>
                    {documentUrl?.length ? (
                      <a
                        href={documentUrl || ''}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {documentUrl?.includes('google') ? (
                          <>
                            <img
                              src={DRIVE_ICON}
                              alt="drive"
                              style={{ width: '23px' }}
                            />
                            {name
                              ? name.length > 25
                                ? `${name.substring(0, 25)}...`
                                : name
                              : 'no name'}
                          </>
                        ) : (
                          <img src={FILE_ICON} alt="file" />
                        )}
                      </a>
                    ) : (
                      <Box component="span">--</Box>
                    )}
                  </Grid>
                </Grid>
              );
            })}
        </Hidden>
      </Grid>
    );
  }

  const mapCommonAppEssays = (studentApplicationEssays: any) => {
    let commonEss: typeof studentApplicationEssays = [];
    let common = 0,
      coalition = 0;
    const essays = [...studentApplicationEssays];
    for (let j = 0; j < essays?.length; j++) {
      if (
        essays[j]?.essayTemplate?.commonEssay === true &&
        common === 0 &&
        isSubstring(essays[j]?.essayTemplate?.shortDescription, 'common')
      ) {
        commonEss.push(essays[j]);
        common++;
      }
      if (
        essays[j]?.essayTemplate?.commonEssay === true &&
        coalition === 0 &&
        isSubstring(essays[j]?.essayTemplate?.shortDescription, 'coalition')
      ) {
        commonEss.push(essays[j]);
        coalition++;
      }
      if (coalition === 1 && common === 1) {
        break;
      }
    }
    return commonEss || [];
  };

  return (
    <>
      <Header navigateTo={history.push} />
      <Toast
        open={openSnackbar}
        autoHideDuration={3000}
        message={snackbarMessage}
        close={handleNotificationClose}
        type="success"
      />
      <Query<StudentApplicationDetailNewQuery>
        query={STUDENT_APPLICATION_QUERY}
        variables={{ studentApplicationId }}
        fetchPolicy="no-cache"
      >
        {({ data, refetch, loading, networkStatus }) => {
          if (loading && networkStatus !== 4) return <Loader />;
          if (!data || !data.StudentApplication) return null;
          const {
            StudentApplication: {
              Student,
              applicationRequirement,
              studentApplicationEssays,
              studentApplicationChecklist,
              studentApplicationCampuses,
              id: studentApplicationId,
              applicationStatus,
              otherApplicationDeadlines,
              decisionStatus,
              decision,
              decisionDeadline,
              ApplicationCampusesSurvey,
              commonEssays: ApplicationCommonEssays,
              graduatedEarlyDecisionDeadline,
              graduatedRegularDecisionDeadline,
              graduatedEarlyActionDeadline,
              studentApplicationMajors,
            },
          } = data;
          const row = {
            id: Number(id),
            decisionValue: decision,
            ApplicationCampusesSurvey: ApplicationCampusesSurvey,
          };
          if (!applicationRequirement) return null;
          const {
            earlyActionDeadline,
            earlyDecisionDeadline,
            regularDecisionDeadline,
            college,
          } = applicationRequirement;
          if (!college) return null;
          const {
            name: collegeName,
            logo,
            styleConfig,
            Campuses,
            parentMetaData,
            isUCCollege,
            allMajors,
          } = college;
          const filterUCCollegeDecision = row?.ApplicationCampusesSurvey?.find(
            (item) => item?.id && item?.id === campusId
          )?.decision;
          if (!Student) return null;
          const {
            pictureUrl: studentPictureUrl,
            firstName: studentFirstName,
            gapYear,
          } = Student;
          const requiredSupplementalEssays: typeof studentApplicationEssays =
            [];
          const OtherEssays: typeof studentApplicationEssays = [];
          let commonEssays: typeof studentApplicationEssays = [];

          const bgDark = (styleConfig && styleConfig.bgDark) || '';
          const AppEssays = studentApplicationEssays || [];

          const deadlines =
            otherApplicationDeadlines?.map((deadline) => {
              return {
                display: deadline?.DeadlineName,
                key: deadline?.DeadlineName,
                value: deadline?.DeadlineDate,
                delete: true,
                id: deadline?.id,
              };
            }) || [];
          if (!Student.graduated) {
            earlyDecisionDeadline &&
              deadlines.push({
                display: 'Early Decision',
                key: 'EarlyDecision',
                value: earlyDecisionDeadline,
                delete: false,
                id: null,
              });

            earlyActionDeadline &&
              deadlines.push({
                display: 'Early Action',
                key: 'EarlyAction',
                value: earlyActionDeadline,
                delete: false,
                id: null,
              });

            regularDecisionDeadline &&
              deadlines.push({
                display: 'Regular Decision',
                key: 'RegularDecision',
                value: regularDecisionDeadline,
                delete: false,
                id: null,
              });
          } else {
            graduatedEarlyDecisionDeadline &&
              deadlines.push({
                display: 'Early Decision',
                key: 'EarlyDecision',
                value: graduatedEarlyDecisionDeadline,
                delete: false,
                id: null,
              });

            graduatedEarlyActionDeadline &&
              deadlines.push({
                display: 'Early Action',
                key: 'EarlyAction',
                value: graduatedEarlyActionDeadline,
                delete: false,
                id: null,
              });

            graduatedRegularDecisionDeadline &&
              deadlines.push({
                display: 'Regular Decision',
                key: 'RegularDecision',
                value: graduatedRegularDecisionDeadline,
                delete: false,
                id: null,
              });
          }

          deadlines.push({
            display: 'Add New',
            key: 'AddNew',
            value: 'addNewDeadline',
            delete: false,
            id: null,
          });
          const applicationStatuses = [
            {
              key: 'notStarted',
              value: 'Not Started',
              className: 'statusMenu notStarted',
            },
            {
              key: 'Completed',
              value: 'Completed',
              className: 'statusMenu Completed',
            },
            {
              key: 'inProgress',
              value: 'In Progress',
              className: 'statusMenu inProgress',
            },
          ];

          const deadlineDueIn =
            decisionDeadline &&
            Moment.utc(decisionDeadline).diff(Moment(), 'days');

          let extraCurricularActivitiesList: typeof studentApplicationEssays =
            [];
          if (Campuses && Campuses?.length > 1) {
            studentApplicationEssays?.forEach((essay) => {
              if (
                essay?.essayTemplate?.essayTitle?.trim() ===
                'UC Extracurricular Activities List'
              ) {
                extraCurricularActivitiesList?.push(essay);
              }
            });
          }
          commonEssays = mapCommonAppEssays(studentApplicationEssays) || [];

          const formatName = (name: string) => {
            const hasCampus = Campuses && Campuses?.length > 1 ? true : false;
            return hasCampus ? name.split('-')[0] : name;
          };
          const campus = studentApplicationCampuses?.find(
            (campus) => campus?.id === campusId
          );

          const handleApplicationDecisionDeadline = async (
            val: string,
            key: string
          ) => {
            if (val && key) {
              const gapYearValue = gapYear ? 1 : 0;
              const highSchoolYear = Student?.highSchoolYear
                ? parseInt(Student?.highSchoolYear)
                : new Date().getFullYear();
              const deadline = moment.utc(val);
              const deadlineMonth = deadline.month() + 1;
              if (!Student?.graduated) {
                if (deadline?.year() < highSchoolYear) {
                  if (deadlineMonth > 9 && deadlineMonth < 13) {
                    deadline.year(highSchoolYear + gapYearValue - 1);
                  } else {
                    deadline.year(highSchoolYear + gapYearValue);
                  }
                }
              }

              try {
                setDeadlineUpdating(true);
                const res = await client.mutate({
                  mutation: UPDATE_APPLICATION_STATUS_AND_DEADLINE,
                  variables: {
                    decisionStatus: key,
                    decisionDeadline: deadline,
                    StudentApplicationId: studentApplicationId,
                  },
                });
                if (res) {
                  await refetch();
                  setDeadlineUpdating(false);
                }
              } catch (err: any) {
                setDeadlineUpdating(false);
                setSnackOpen(true);
                setSnackMessage(
                  err?.message || 'An error occured. Please try again later'
                );
              }
            }
          };

          const handleDeleteNewDeadline = async (
            id: number,
            deadlines: any,
            refetch: Function,
            update?: boolean
          ) => {
            setDeadlineUpdating(true);
            if (id) {
              const res = await client.mutate({
                mutation: DELETE_APPLICATION_DEADLINE,
                variables: {
                  deadlineId: id,
                },
              });
              const deadlineTobeChanged = deadlines.find(
                (deadline: any) => deadline.id !== id
              );

              if (res && update) {
                try {
                  await client.mutate({
                    mutation: UPDATE_APPLICATION_STATUS_AND_DEADLINE,
                    variables: {
                      decisionStatus: deadlineTobeChanged?.key || null,
                      decisionDeadline: deadlineTobeChanged?.value || null,
                      StudentApplicationId: studentApplicationId,
                    },
                  });
                  await refetch();
                  setDeadlineUpdating(false);
                  setAppDeadline('');
                } catch (err: any) {
                  setDeadlineUpdating(false);
                  setAppDeadline('');
                  setSnackOpen(true);
                  setSnackMessage(
                    err.message || 'An error occured. Please try again later'
                  );
                }
              } else {
                await refetch();
                setDeadlineUpdating(false);
                setAppDeadline('');
              }
            } else {
              setDeadlineUpdating(false);
              setSnackOpen(true);
              setSnackMessage('Cannot delete Please try again later');
            }
          };

          const {
            logo: parentLogo = '',
            bgColor: parentHeaderColor = '',
            name: ParentCampusName,
          } = parentMetaData || {};

          const backgroundImage = parentMetaData
            ? `url( ${parentLogo})`
            : `url( ${logo || CollegeDefaultImg} )`;

          const backgroundImageCampus = `url( ${
            parentMetaData
              ? campus
                ? campus.logo
                : parentLogo
              : logo || CollegeDefaultImg
          } )`;

          let campusBgDark = parentMetaData
            ? campus
              ? campus?.styleConfig?.bgDark
              : parentHeaderColor
            : bgDark;

          return (
            <>
              <>
                <Paper
                  className={classes.breadCrumbCollege}
                  style={{
                    background: campusBgDark ? campusBgDark : bgDark,
                  }}
                >
                  <Container
                    maxWidth={false}
                    className={classes.containerRelative}
                  >
                    <Grid className={classes.mobilePaddingBreadcrumb}>
                      <IconButton
                        className={tableClasses.previousPage}
                        onClick={() => history.goBack()}
                      >
                        <img src={BREADCRUMB_LINK_IMG} alt="" />
                      </IconButton>
                      <Box className={classes.collegeName}>
                        <Box
                          className={classes.collegeLogoAvatar}
                          style={{
                            backgroundImage: backgroundImageCampus,
                          }}
                        >
                          {parentMetaData && campus && (
                            <Box
                              className={classes.collegeSubCategroryLogo}
                              style={{
                                backgroundImage,
                                border: `2px solid ${bgDark}`,
                              }}
                            ></Box>
                          )}
                        </Box>

                        <Box className="collegeNameSpacing">
                          {parentMetaData && campus && (
                            <Typography variant="h6">
                              MEMBER OF {ParentCampusName} SYSTEM
                            </Typography>
                          )}

                          <Tooltip classes={tooltipClasses} title={collegeName}>
                            <Typography variant="h2">
                              {campus ? campus?.name : formatName(collegeName)}
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                  </Container>
                </Paper>
              </>

              <Container maxWidth={false}>
                <Box className={applicationClasses.applicationHeaderWrapper}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    className={applicationClasses.applicationHeader}
                  >
                    <Grid>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={applicationClasses.userNameApplication}
                      >
                        <Box className="applicationAvatar">
                          <img
                            src={studentPictureUrl || USER_ICON}
                            alt="avatar"
                          />
                        </Box>

                        <Typography variant="h3">
                          {`${studentFirstName}'s Application`}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <FormGroup row>
                          <Query<any>
                            query={GET_ASSIST_STATUS}
                            variables={{ studentId: Student.id }}
                            fetchPolicy="no-cache"
                          >
                            {({ data, refetch: refetchGetAssistStatus }) => {
                              if (data) {
                                const { getAssistStatus } = data;
                                if (getAssistStatus) {
                                  getAssistStatus?.map(
                                    (object: any, index: number) => {
                                      if (
                                        object.ApplicationRequirementId ===
                                        applicationRequirement?.id
                                      ) {
                                        setAssistId(object.id);
                                        setIsAssistChecked(object.isAssist);
                                      }
                                    }
                                  );
                                }
                              } else {
                                console.log('Assist update failed', data);
                              }
                              return (
                                <Box>
                                  <Box
                                    display="inline-block"
                                    className={applicationClasses.collegeAssist}
                                  >
                                    <AdvisorIconTooltip title="Charge application towards student entitlements." />
                                  </Box>
                                  <FormControlLabel
                                    label="Support"
                                    className={
                                      applicationClasses.checkBoxSupportBSMD
                                    }
                                    control={
                                      <Checkbox
                                        checked={isAssistChecked}
                                        inputProps={{
                                          'aria-label': 'assist checkbox',
                                        }}
                                        color="primary"
                                        name="Support"
                                        onChange={(e) => {
                                          handleSetAssistStatus(
                                            e.target.checked,
                                            assistId,
                                            refetchGetAssistStatus,
                                            Student.id,
                                            (Campuses && Campuses.length > 1) ||
                                              false
                                          );
                                        }}
                                      />
                                    }
                                  />
                                </Box>
                              );
                            }}
                          </Query>
                          <Query<any>
                            query={GET_BSMD_STATUS}
                            variables={{ studentId: Student.id }}
                            fetchPolicy="no-cache"
                          >
                            {({ data, refetch: refetchGetBsmdStatus }) => {
                              if (data) {
                                const { getBsmdStatus } = data;
                                if (getBsmdStatus) {
                                  getBsmdStatus?.map(
                                    (object: any, index: number) => {
                                      if (
                                        object.ApplicationRequirementId ===
                                        applicationRequirement?.id
                                      ) {
                                        setBsmdId(object.id);
                                        setIsBSMDChecked(object.isBSMD);
                                      }
                                    }
                                  );
                                }
                              } else {
                                console.log('bsmd update failed', data);
                              }
                              return Campuses && Campuses.length > 1 ? null : (
                                <Box>
                                  <FormControlLabel
                                    label="BSMD"
                                    className={
                                      applicationClasses.checkBoxSupportBSMD
                                    }
                                    control={
                                      <Checkbox
                                        checked={isBSMDChecked}
                                        inputProps={{
                                          'aria-label': 'BSMD checkbox',
                                        }}
                                        color="primary"
                                        name="BSMD"
                                        onChange={(e) => {
                                          handleSetBsmdStatus(
                                            e.target.checked,
                                            bsmdId,
                                            refetchGetBsmdStatus,
                                            Student.id
                                          );
                                        }}
                                      />
                                    }
                                  />
                                  <CustomTooltip
                                    className={applicationClasses.bsmdSupport}
                                    title="Please check the checkbox if BSMD application"
                                  />
                                </Box>
                              );
                            }}
                          </Query>
                        </FormGroup>
                      </Box>

                      <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Typography
                          variant="caption"
                          className={classes.mobileFieldHeading}
                        >
                          Application Status
                        </Typography>
                      </Hidden>
                      <Box
                        className={applicationClasses.selectionDropDownStyles}
                      >
                        <FormControl>
                          <Select
                            defaultValue={
                              applicationStatuses.find(
                                (status) => status.key === applicationStatus
                              )?.key
                            }
                            onChange={(e) =>
                              handleChangeApplicationStatus(
                                e.target.value as string,
                                studentApplicationId,
                                refetch
                              )
                            }
                            variant="outlined"
                            className={`statusMenu ${
                              applicationStatuses.find(
                                (status) => status.key === applicationStatus
                              )?.key
                            }`}
                          >
                            {applicationStatuses.map((status, index) => (
                              <MenuItem
                                key={index}
                                value={status?.key}
                                className={status.className}
                              >
                                {status?.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {disableDropDown(
                          decisionDeadline,
                          Student?.highSchoolYear || '',
                          true
                        ) && (
                          <Grid className={classes.dropStyleApplication}>
                            <Hidden only={['md', 'lg', 'xl']}>
                              <Typography
                                variant="caption"
                                className={classes.mobileFieldHeading}
                              >
                                Decision Status
                              </Typography>
                            </Hidden>
                            {!isUCCollege || campusId ? (
                              <ChangeApplicationStatus
                                userId={advisorId}
                                refetch={refetch}
                                appDecision={
                                  isUCCollege && campusId
                                    ? filterUCCollegeDecision
                                    : row?.decisionValue
                                }
                                appId={row?.id}
                                loading={loading}
                                setLoading={setLoading}
                                collegeId={campusId}
                                handleNotificationPopup={
                                  handleNotificationPopup
                                }
                                campusesRow={row?.ApplicationCampusesSurvey}
                              />
                            ) : (
                              ''
                            )}
                          </Grid>
                        )}
                      </Box>

                      {(!isUCCollege || !isNaN(campusId)) &&
                        (() => {
                          let applicationMajors = studentApplicationMajors;
                          if (campusId) {
                            applicationMajors =
                              studentApplicationMajors?.filter(
                                (value) =>
                                  value?.ApplicationCampusId === campusId
                              ) || [];
                          }

                          return (
                            <Box>
                              <Box mt={2}>
                                {!applicationMajors?.length ? (
                                  <Box
                                    onClick={handleToggleMajorsDialog}
                                    className={classes.addApplicationMajor}
                                  >
                                    Add Major
                                  </Box>
                                ) : (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    className={classes.editApplicationMajor}
                                  >
                                    <Typography variant="body1">
                                      <Typography
                                        variant="body1"
                                        className="addMajors"
                                      >
                                        {applicationMajors
                                          ?.map((major) => `${major?.name}`)
                                          .join('; ')}
                                      </Typography>
                                    </Typography>

                                    <IconButton
                                      onClick={handleToggleMajorsDialog}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>

                              <AddEditApplicationMajor
                                open={addMajorDialog}
                                info="underGraduation"
                                onClose={handleToggleMajorsDialog}
                                refetch={refetch}
                                majors={allMajors}
                                applicationId={studentApplicationId}
                                applicationCampusId={campusId}
                                userMajorData={applicationMajors}
                              />
                            </Box>
                          );
                        })()}
                    </Grid>

                    <Grid>
                      <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Typography
                          variant="caption"
                          className={classes.mobileFieldHeading}
                        >
                          Deadline
                        </Typography>
                      </Hidden>

                      {deadlineUpdating && (
                        <Box
                          marginBottom={1}
                          maxWidth="210px"
                          marginLeft="auto"
                        >
                          <LinearProgress />
                        </Box>
                      )}

                      {deadlines?.length > 1 ? (
                        <FormControl
                          className={applicationClasses.customDeadlineMenu}
                        >
                          <ThemeProvider theme={customTheme}>
                            <Select
                              variant="outlined"
                              MenuProps={MenuProps}
                              value={
                                deadlines.find(
                                  (deadline) => deadline.key === decisionStatus
                                )?.key || ''
                              }
                              className={`selectMedium mb-3 ${selectColor(
                                deadlines
                              )}
                              `}
                            >
                              {deadlines &&
                                deadlines?.map((deadline) => (
                                  <MenuItem
                                    key={deadline?.key}
                                    value={deadline?.key || ''}
                                    className={
                                      deadline.value === 'addNewDeadline'
                                        ? menuClasses.menuItem
                                        : menuClasses.menuItemSimple
                                    }
                                  >
                                    <Box display="flex" alignItems="center">
                                      <span
                                        onClick={async (e: any) => {
                                          if (
                                            deadline?.value === 'addNewDeadline'
                                          ) {
                                            setOpenNewDeadlineModal(true);
                                          } else {
                                            const decisionVal =
                                              deadline?.value as string;
                                            const updatedDeadline =
                                              deadlines.find((_deadline) => {
                                                return (
                                                  (_deadline.value ===
                                                    decisionVal &&
                                                    _deadline.key ===
                                                      deadline?.key) ||
                                                  ''
                                                );
                                              });

                                            if (
                                              updatedDeadline?.key &&
                                              updatedDeadline?.key !==
                                                decisionStatus
                                            ) {
                                              await handleApplicationDecisionDeadline(
                                                decisionVal,
                                                updatedDeadline.key
                                              );
                                            }
                                          }
                                        }}
                                      >
                                        {deadline?.display}
                                      </span>
                                      {(!(
                                        deadline?.value === decisionDeadline &&
                                        deadline?.display === decisionStatus
                                      ) ||
                                        (deadlines.length === 2 &&
                                          otherApplicationDeadlines?.length ===
                                            1)) &&
                                        deadline.delete &&
                                        deadline.display !== decisionStatus && (
                                          <Box marginRight={'10px'}>
                                            <img
                                              key={deadline.display}
                                              onClick={(e: any) => {
                                                e.preventDefault();

                                                if (deadline.id)
                                                  handleDeleteNewDeadline(
                                                    deadline.id,
                                                    deadlines,
                                                    refetch
                                                  );
                                              }}
                                              src={TrashIcon}
                                              alt="trash"
                                            />
                                          </Box>
                                        )}
                                    </Box>
                                  </MenuItem>
                                ))}
                            </Select>
                          </ThemeProvider>
                        </FormControl>
                      ) : (
                        <ThemeProvider theme={customTheme}>
                          <Button
                            startIcon={<img src={AddIcon} alt="Add" />}
                            color="primary"
                            variant="text"
                            onClick={() => {
                              setOpenNewDeadlineModal(true);
                            }}
                          >
                            Add new Deadline
                          </Button>
                        </ThemeProvider>
                      )}

                      <Grid className={applicationClasses.decisionSelectMenu}>
                        <Box className="dateText">
                          <Typography variant="h6">
                            {decisionDeadline
                              ? deadlineDueIn > 0
                                ? `Due In ${deadlineDueIn} Days`
                                : `Deadline Passed ${
                                    deadlineDueIn * -1
                                  } Days ago`
                              : ''}
                          </Typography>
                          <Typography variant="body1">
                            {decisionDeadline
                              ? Moment.utc(decisionDeadline).format('LL')
                              : ''}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  {Campuses && Campuses?.length > 1 && (
                    <Grid className={applicationClasses.selectsubCampus}>
                      <Typography variant="h5">Select UC Campuses</Typography>
                      <Typography variant="body1" className="subCampusSubtitle">
                        The University of California System simplifies the
                        process of applying and offers a single application
                        across all their campuses.
                      </Typography>
                      <CampusesCheckBoxes
                        Campuses={Campuses}
                        ApplicationCreatedForCampuses={
                          studentApplicationCampuses
                        }
                      />
                    </Grid>
                  )}
                </Box>

                <Grid>
                  {!isUCCollege || campusId ? (
                    <>
                      <Grid
                        className={clsx(
                          applicationClasses.addCommonEssay,
                          applicationClasses.sectionSpacingBorder
                        )}
                      >
                        <Typography variant="h5">
                          Common App/Coalition App Essay
                        </Typography>

                        <Grid>
                          {ApplicationCommonEssays &&
                            ApplicationCommonEssays.length < 1 && (
                              <Typography>--</Typography>
                            )}
                        </Grid>

                        <Grid>
                          {ApplicationCommonEssays &&
                            ApplicationCommonEssays?.length > 0 && (
                              <SupplementalEssays
                                AppEssays={ApplicationCommonEssays}
                                titleEssay={null}
                                refetch={refetch}
                                handleChangeEssaystatus={
                                  handleChangeEssayStatus
                                }
                              />
                            )}
                        </Grid>
                      </Grid>

                      <Dialog
                        open={openCommonEssayModal}
                        onClose={() => setOpenCommonEssayModal(false)}
                        className={applicationClasses.commonDialogFeature}
                      >
                        <Box
                          className={applicationClasses.dialogHeader}
                          display="flex"
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography variant="h5">
                              Common App/Coalition App Essay
                            </Typography>
                            <Typography variant="caption">
                              Select One
                            </Typography>
                          </Box>

                          <IconButton
                            onClick={() => setOpenCommonEssayModal(false)}
                          >
                            <img src={DIALOG_CLOSE_ICON} alt="close" />
                          </IconButton>
                        </Box>
                        <DialogContent>
                          <Grid>
                            {commonEssays &&
                              commonEssays.length &&
                              showCommonEssayModalData(
                                commonEssays,
                                refetch,
                                () => setOpenCommonEssayModal(false)
                              )}
                          </Grid>
                        </DialogContent>
                      </Dialog>
                    </>
                  ) : (
                    ''
                  )}

                  <Grid>
                    {AppEssays &&
                      AppEssays.map((essay) => {
                        if (!essay || !essay.essayTemplate) return;
                        const {
                          essayTemplate: { commonEssay, Order },
                        } = essay;
                        if (!commonEssay && Order && Order > 0) {
                          requiredSupplementalEssays.push(essay);
                        }
                      })}

                    {requiredSupplementalEssays.length > 0 && (
                      <Grid className={applicationClasses.addCommonEssay}>
                        <Typography variant="h5">
                          {Campuses && Campuses?.length > 1
                            ? 'Personal Insight Questions'
                            : 'Supplemental Essays'}
                        </Typography>
                        <SupplementalEssays
                          AppEssays={requiredSupplementalEssays}
                          titleEssay={
                            Campuses && Campuses?.length > 1
                              ? 'Personal Insight Q'
                              : 'Supplemental Essay'
                          }
                          refetch={refetch}
                          handleChangeEssaystatus={handleChangeEssayStatus}
                          studentGraduted={Student.graduated || false}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid className={applicationClasses.otherEssaysSection}>
                    {AppEssays &&
                      AppEssays.map((essay) => {
                        if (!essay || !essay.essayTemplate) return;
                        const {
                          essayTemplate: { required, specificSchool },
                        } = essay;
                        if (!required && !specificSchool) {
                          OtherEssays.push(essay);
                        }
                      })}

                    <Query<any>
                      query={OTHER_ESSAY_QUERY}
                      variables={{ applicationId: studentApplicationId }}
                      fetchPolicy="no-cache"
                    >
                      {({ data, refetch: refetchOtherEssayQuery }) => {
                        if (!data?.studentApplicationOtherEssays) return null;
                        const { studentApplicationOtherEssays } = data;
                        return (
                          studentApplicationOtherEssays.length > 0 && (
                            <>
                              <Typography
                                variant="h5"
                                className={clsx(
                                  applicationClasses.applicationEssayHeading,
                                  applicationClasses.headingBorderMobile
                                )}
                              >
                                Other Essays
                              </Typography>
                              <SupplementalEssays
                                AppEssays={studentApplicationOtherEssays}
                                titleEssay={'Other Essay'}
                                studentGraduted={Student.graduated || false}
                                refetch={refetch}
                                handleChangeEssaystatus={
                                  handleChangeOtherEssayStatus
                                }
                              />
                            </>
                          )
                        );
                      }}
                    </Query>
                  </Grid>

                  <StudentApplicationTodos
                    studentApplicationChecklist={studentApplicationChecklist}
                  />

                  <AddNewApplicationDealine
                    open={openNewDeadlineModal}
                    collegeLogo={parentLogo ? parentLogo : logo}
                    onCloseModal={() => setOpenNewDeadlineModal(false)}
                    onSubmitModal={(variables: any) => {
                      handleAddNewDeadline(
                        variables,
                        handleApplicationDecisionDeadline
                      );
                    }}
                    deadlines={deadlines}
                  />
                </Grid>
              </Container>
            </>
          );
        }}
      </Query>
      <FooterNew />
    </>
  );
};

export default ApplicationDetailPage;
