// imports
import { CloseRounded } from '@material-ui/icons';
import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  TooltipProps,
  Typography,
} from '@material-ui/core';
import { FC, Fragment, useState, useEffect, useContext, useRef } from 'react';
// contexts
import { AppContext } from '../contexts';
import { ChatBotWidgetProps } from '../common/interfaces';
// assets
import fonts from '../common/fonts';
import colors from '../common/colors';
import { ExpandIcon, MinimizeIcon } from '../pages/main/students/assets/svg';
// styles
import { useStyles } from './styles';
import { dfMessengerBotType, SALES_USER_EMAIL } from '../constants';
import { getCookie, saveDfMessengerSession } from '../common/utility';

const useStylesBootstrap = makeStyles((theme) => ({
  popper: {
    zIndex: 99999999999,
  },

  arrow: {
    width: 22,
    height: 16,
    marginTop: (props: { isFullScreen: boolean }) =>
      props.isFullScreen ? '-16px !important' : undefined,
    marginBottom: (props: { isFullScreen: boolean }) =>
      !props.isFullScreen ? '-16px !important' : undefined,
    marginLeft: -4,
    color: colors.BLACK_THIRTEEN,
  },

  arrowSalesChatbot: {
    width: 22,
    height: 16,
    marginLeft: -4,
    marginTop: '-16px !important',
    color: colors.BLACK_THIRTEEN,
  },

  tooltip: {
    padding: 16,
    borderRadius: 6,
    backgroundColor: colors.BLACK_THIRTEEN,
    border: `1px solid ${colors.TOOLTIP_BORDER}`,
    boxShadow: `0px 0px 1px 0px ${colors.TOOLTIP_SHADOW_PRIMARY}, 0px 40px 64px 0px ${colors.TOOLTIP_SHADOW_SECONDARY}`,

    "& [class*='MuiTypography-body1']": {
      fontSize: 12,
      fontWeight: 400,
      color: colors.WHITE_ONE,
      fontFamily: fonts.INTER,
    },

    '& a': {
      fontSize: 12,
      marginLeft: 5,
      fontWeight: 400,
      color: colors.WHITE_ONE,
      fontFamily: fonts.INTER,
      borderBottom: `1px solid ${colors.WHITE_ONE}`,
    },
  },
}));

const ChatBotWidget: FC<ChatBotWidgetProps> = ({
  isOpen,
  handleClose,
  isAdvisorChatbot,
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);
  const { email } = user || {};
  const salesUserEmail = getCookie(SALES_USER_EMAIL);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isVisible, setIsVisible] = useState(isOpen);

  const dfMessengerRef = useRef<HTMLDivElement | null>(null);

  window.addEventListener('df-messenger-loaded', async () => {
    const dfMessenger = dfMessengerRef.current as any;
    const isNewSession = window.dfMessenger?.detail?.isNew;
    const sessionId = window.dfMessenger?.detail?.sessionId;
    const isMentor = user?.roles?.includes('Mentor');
    if (dfMessenger && isNewSession) {
      if (window.dfMessenger && window.dfMessenger.detail) {
        window.dfMessenger.detail.isNew = false;
      }
      await dfMessenger.sendQuery('hi', true);
      saveDfMessengerSession(
        email || salesUserEmail || '',
        sessionId,
        isMentor ? dfMessengerBotType.advisor : dfMessengerBotType.sales
      );
    }
  });

  window.addEventListener('df-chip-clicked', function (event: any) {
    if (event.detail.text === 'End Chat') {
      sessionStorage.clear();
    }
  });

  window.addEventListener('df-user-input-entered', function (event: any) {
    const userInput = event.detail.input.toLowerCase();
    if (userInput === 'end chat') {
      sessionStorage.clear();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }
  }, [isOpen]);

  useEffect(() => {
    document.body.style.overflow = isFullScreen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isFullScreen]);

  const handleExpand = () => {
    setIsFullScreen((prev) => !prev);
  };

  const handleCloseWithTransition = () => {
    setIsVisible(false);
    setTimeout(handleClose, 500);
  };

  function BootstrapTooltip(props: JSX.IntrinsicAttributes & TooltipProps) {
    const classes = useStylesBootstrap({ isFullScreen });

    return (
      <Tooltip
        classes={{
          popper: classes.popper,
          tooltip: classes.tooltip,
          arrow: isAdvisorChatbot ? classes.arrow : classes.arrowSalesChatbot,
        }}
        arrow
        {...props}
      />
    );
  }

  const TooltipContent = ({ url }: { url: string }) => (
    <Fragment>
      <Typography variant="body1">
        This tool is designed to evolve and improve over time. Please use
        responsibly and verify critical information as needed.
        <a href={url} target="_blank">
          Learn More
        </a>
      </Typography>
    </Fragment>
  );

  const TooltipBox = ({
    top,
    left,
    url,
  }: {
    top: number;
    left: number;
    url: string;
  }) => (
    <BootstrapTooltip
      title={<TooltipContent url={url} />}
      arrow
      interactive
      placement="top"
    >
      <Box
        top={top}
        left={left}
        fontSize={12}
        display="flex"
        fontWeight={600}
        borderRadius={4}
        padding="0px 4px"
        textAlign="center"
        alignItems="center"
        position="absolute"
        justifyContent="center"
        color={colors.WHITE_ONE}
        fontFamily={fonts.INTER}
        style={{ cursor: 'pointer' }}
        bgcolor={colors.BEAT_BADGE_BLUE}
      >
        Beta
      </Box>
    </BootstrapTooltip>
  );

  return (
    <Fragment>
      {isAdvisorChatbot ? (
        <Fragment>
          {isFullScreen && (
            <div
              style={{
                opacity: isVisible ? 1 : 0,
                visibility: isVisible ? 'visible' : 'hidden',
              }}
              className={classes.chatbotFullScreenOverlay}
            />
          )}

          <div
            style={{
              opacity: isVisible ? 1 : 0,
              visibility: isVisible ? 'visible' : 'hidden',
              transition:
                'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out',
            }}
          >
            <df-messenger
              ref={dfMessengerRef}
              language-code="en"
              allow-feedback="all"
              project-id="usn-dev"
              max-query-length="-1"
              agent-id="e448c1fb-afa8-4849-b73e-d56993553224"
              style={{
                position: 'fixed',
                borderRadius: 15,
                zIndex: isFullScreen ? '999999999999' : '99',
                bottom: isFullScreen ? 70 : 19,
                backgroundColor: colors.WHITE_ONE,
                right: isFullScreen ? '20%' : 150,
                transition: 'all 0.5s ease-in-out',
                width: isFullScreen ? '60%' : '500px',
                border: `1px solid ${colors.WHITE_SEVEN}`,
                boxShadow: `0px 4px 14px 0px ${colors.RGBA_COLOR_ONE}`,
                height: isFullScreen ? 'calc(100vh - 140px)' : '601px',
              }}
            >
              <df-messenger-chat chat-title="CollegeAdvisor AI"></df-messenger-chat>

              <TooltipBox
                top={30}
                left={236}
                url="https://collegeadvisor.notion.site/CollegeAdvisor-AI-Beta-8e8c623485ae4e29980ae0f962b4eb2b?pvs=4"
              />

              <IconButton
                onClick={handleCloseWithTransition}
                className={classes.closeButtonChatbot}
              >
                <CloseRounded />
              </IconButton>

              <IconButton
                onClick={handleExpand}
                className={classes.minMaxButtonChatbot}
              >
                {isFullScreen ? <MinimizeIcon /> : <ExpandIcon />}
              </IconButton>
            </df-messenger>
          </div>
        </Fragment>
      ) : (
        <Box position="relative" p={3}>
          <Box
            width="100%"
            borderRadius={10}
            bgcolor={colors.WHITE_ONE}
            height="calc(100vh - 48px)"
            border={`1px solid ${colors.WHITE_SEVEN}`}
            boxShadow={`0px 4px 14px 0px ${colors.RGBA_COLOR_ONE}`}
          >
            <df-messenger
              ref={dfMessengerRef}
              language-code="en"
              allow-feedback="all"
              project-id="usn-dev"
              max-query-length="-1"
              agent-id="abfa8f85-0ec1-4d9d-88f5-adbd21215a92"
            >
              <df-messenger-chat chat-title="CollegeAdvisor Sales Assistant"></df-messenger-chat>

              <TooltipBox
                top={56}
                left={410}
                url="https://www.notion.so/collegeadvisor/CollegeAdvisor-AI-Beta-SALES-6874ccc59fb54b64827322a766201d1a?pvs=4"
              />
            </df-messenger>
          </Box>
        </Box>
      )}
    </Fragment>
  );
};

export default ChatBotWidget;
