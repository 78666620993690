import { FC, Fragment, useContext, useState } from 'react';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { Grid, Button, Card, Typography, Box } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import { AppContext } from '../../contexts';
import { TextField } from '../../components/fields';
import { TOKEN } from '../../constants';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import CustomAlert from '../../components/CustomAlert';

import {
  SignInWithEmailMutation,
  SignInWithEmailMutationVariables,
} from './__generated__/SignInWithEmailMutation';
import { useStyles } from './styles';
import { handleMutationError } from '../../common/utility';
import AccountLockedAlert from '../../components/AccountLockedAlert';

export const LOGIN_WITH_EMAIL_MUTATION = gql`
  mutation SignInWithEmailMutation($email: String!, $password: String!) {
    signInMentor(input: { email: $email, password: $password }) {
      token
    }
  }
`;

const LoginPage: FC<RouteComponentProps> = ({ history }) => {
  const appContext = useContext(AppContext);
  const { user } = useContext(AppContext);
  const urlParams = new URLSearchParams(window.location.search);
  const goBack = urlParams.get('_goback');

  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState('');
  const [authError, setAuthError] = useState<string>('');

  function toggleAlert(variant?: string, message?: string) {
    setVariant(variant || '');
    setMessage(message || '');
  }

  const initialValues = {
    email: '',
    password: '',
  };
  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
  });
  const classes = useStyles();
  return (
    <Grid>
      {user &&
        (goBack ? <Redirect to={goBack} /> : <Redirect to="/meetings" />)}
      <Header />

      <Grid className={classes.contentBoxHeight}>
        <Grid className={classes.formContainer}>
          <Box maxWidth={400}>
            {authError && (
              <Fragment>
                {authError.includes('ACCOUNT_LOCKED') ? (
                  <AccountLockedAlert
                    title="Your Account Has Been Temporarily Locked"
                    subTitle={
                      <Fragment>
                        {' '}
                        Password attempt limit reached. Regain access by{' '}
                        <Link to="/forgot-password">
                          resetting your password
                        </Link>{' '}
                        and following the &nbsp; emailed instructions. Contact
                        success@collegeadvisor.com for additional support.
                      </Fragment>
                    }
                  />
                ) : (
                  <AccountLockedAlert
                    title={authError?.split('-')[0]}
                    subTitle={<Fragment>{authError?.split('-')[1]}</Fragment>}
                  />
                )}
              </Fragment>
            )}
          </Box>

          <Card className={classes.card}>
            <CustomAlert
              variant={variant}
              message={message}
              toggleAlert={toggleAlert}
            />

            <Mutation<SignInWithEmailMutation, SignInWithEmailMutationVariables>
              mutation={LOGIN_WITH_EMAIL_MUTATION}
            >
              {(signInWithEmailMutation: Function) => {
                return (
                  <Formik
                    initialValues={initialValues}
                    validationSchema={LoginSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        const result = await signInWithEmailMutation({
                          variables: values,
                        });

                        const {
                          data: {
                            signInMentor: { token },
                          },
                        } = result;

                        await localStorage.setItem(TOKEN, token);
                        appContext.setIsLoggedIn(true);
                      } catch (error: any) {
                        let errorMessage = 'Something went wrong!';

                        if (error?.message) {
                          handleMutationError(error, ({ message }) => {
                            if (message) errorMessage = message;
                          });
                        }

                        setAuthError(errorMessage);
                      }
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <Typography
                          variant="h5"
                          component="h5"
                          className={classes.formHeading}
                        >
                          Login
                        </Typography>
                        <Field
                          type="email"
                          name="email"
                          component={TextField}
                          label="Email"
                          fullWidth
                          className={classes.textfield}
                        />

                        <Field
                          type="password"
                          name="password"
                          component={TextField}
                          label="Password"
                          fullWidth
                          className={classes.textfield}
                        />

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                          className={classes.signButton}
                          style={{ textTransform: 'initial' }}
                        >
                          Login
                        </Button>
                      </Form>
                    )}
                  </Formik>
                );
              }}
            </Mutation>
          </Card>

          <Grid className={classes.FormBottomSection}>
            <Link to="/forgot-password/" className={classes.linkUnderline}>
              Forgot password?
            </Link>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </Grid>
  );
};

export default LoginPage;
