import {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Tooltip,
  Box,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  Tabs,
  Tab,
} from '@material-ui/core';

import ParentTable from './ParentsTable';
import ExcludeStudentFromWeeklyAdvisorReportCheckbox from './ExcludeStudentFromWeeklyAdvisorReportCheckbox';
import Advisors from './Advisors';
import StudentApplicationsTable from './StundentApplicationsTable';
import StudentEssaysTable from './StudentEssaysTable';
import StudentNotes from './StudentNotes/StudentNotes';
import MeetingsTable from './MeetingsTable';
import Milestones from './Milestones';
import AdvisorBookingModal from './BookingMeeting/AdvisorBookingModal';
import VideoChatHours from './VideoChatHours';
import VideoChatHoursEnd from './VideoChatHoursEnd';
import ApplicationEntitlement from './applicationEntitlement';
import ApplicationsWithEssayProgress from './ApplicationsWithEssayProgress';
import { useStyles } from './MyStudentsStyle';
import { useStylesTable } from './tableCommonStyle';
import AlertInfoCard from '../../../components/AlertInfoCard';
import BannerMessage from '../../../components/BannerMessage';

import { AppContext } from '../../../contexts';
import Loader from '../../../components/Loader';
import Header from '../../../components/layout/Header';
import TabPanel from '../../../components/TabPanel';
import client from '../../../apollo';
import Colors from '../../../common/colors';
import { checkForCurrentYear, tabA11yProps } from '../../../common/utility';

import USER_ICON from '../../../img/empty-badge.svg';
import MailIcon from '../../../img/mail-icon.svg';
import CallIcon from '../../../img/call-icon.svg';
import Chat_Icon from '../../../img/chat-icon-white.svg';
import BOOK_MEETING_ICON from '../../../img/book-meeting-icon.svg';
import Breadcrumb_Arrow_Icon from '../../../img/breadcrumb_arrow.svg';
import LINK_ICON from '../../../img/link-copy.png';
import LINK_COPY_ICON from '../../../img/link-copied-icon.png';
import Caution_Icon from '../../../img/caution-icon.svg';
import ERROR_ICON from '../../../img/error-ic.svg';

import { StudentDetailQuery } from './__generated__/StudentDetailQuery';
import { allStudentApplicationsQueryWithEssayProgress } from './__generated__/allStudentApplicationsQueryWithEssayProgress';
import StudentProfile from './StudentProfile';
import { AdditionalSurveyDetails } from './AdditionalSurveyDetails';
import {
  associationTypeOptions,
  CAP_ENROLLEE,
  getStudentBonus,
  sortAdvisorsWithLevel,
  StudentDetailsTabs,
} from '../../../constants';
import FindOpportunity from './FindOpportunity';
import FooterNew from '../../../components/layout/FooterNew';
import MBAStudentProfile from './MBAStudentProfile';
import { useTooltipStyles } from '../../../components/CustomTooltip';
import MilestoneActivityDialog from '../../../components/milestoneActivity/MilestoneActivityDialog';
import StudentTimeLogs from './StudentTimeLogs';
import OtherApplicationDialogBox from '../../../components/OtherApplication/OtherApplicationDialogBox';


export const GET_RISK_STATUS = gql`
  query getRiskStatusQuery($StudentId: Int!) {
    getRiskStatus(StudentId: $StudentId) {
      StudentId,
      status,
      CommenterId
    }
  }
`;


const STUDENT_DETAIL_QUERY = gql`
  query StudentDetailQuery($studentId: Int!, $advisorId: Int!) {
    Student(studentId: $studentId, advisorId: $advisorId) {
      id
      firstName
      lastName
      email
      firstMeetingBookedDate {
        id
        startTime
        endTime
      }
      planCancelled
      pictureUrl
      roles
      userToken
      highSchool
      highSchoolYear
      gapYear
      gpa
      sat
      act
      sendBirdChannelUrl
      phoneNumber
      isPremium
      applyingYear
      premiumPackageType
      color
      enrollmentDate
      isMBAGrad
      shortName
      StudentIncentiveBonus(studentId: $studentId) {
        IncentiveBonusPayable
      }
      premiumTotalEssayCount
      premiumEssayCountUsed
      premiumTotalVideoCount
      premiumVideoCountUsed
      totalMilestoneVideoChatUsed
      premiumTotalOfflineCount
      shareDriveUrl
      graduated
      premiumOfflineCountUsed {
        essayCount
        offlineResearch
        MeetingWithAdvisor
        milestoneTeamTotal
        Other
        dressRehearsalCount
        correspondenceTotal
      }
      intakeResponse
      intakeFormToken
      initialKickOffDate
      AdmissionPackageType
      studentDocuments(studentId: $studentId) {
        documentName
        createdAt
        updatedAt
        category
        documentUrl
        essayTitle
        College {
          id
          name
        }
      }
      premiumEntitlements {
        id
        entitlementType
        totalEntitlement
        entitlementCountUsed
        startedAt
        completedAt
      }

      parents {
        firstName
        lastName
        email
        phoneNumber
        highSchool
        intakeResponse
        intakeFormToken
      }

      allStudentMentors {
        startAt
        isExtraMatch
        associateType
        mentor {
          id
          firstName
          lastName
          email
          intakeYear
          highSchoolYear
          phoneNumber
          sendBirdChannelUrl
          advisorLevel
          sendbirdId
          pictureUrl
          currentCollege {
            name
          }
        }
      }
      studentApplicationSurvey {
        earlyActionColleges {
          id
          name
        }
        earlyDecisionCollege {
          id
          name
        }
        finalDecisionCollege {
          id
          name
        }
        finalCollegeDecided
        studentApplications {
          ApplicationCampusesSurvey {
            id
            name
            logo
            decision
          }
          id
          decision
          decisionStatus
          applicationRequirement {
            id
            college {
              id
              logo
              name
              parentMetaData
            }
          }
          studentApplicationCampuses {
            id
            name
            logo
            applicationRequirement {
              id
              acceptRate
            }
          }
        }
        studentId
        scholarshipInfo
        appliedForEarlyAction
        appliedForEarlyDecision
        appliedForScholarship
        completedAt
        initialEmailSentAt
        reminderEmailSentAt
        autoSaveAt
      }
      studentApplications {
        id
        decisionStatus
        applicationStatus
        decisionDeadline
        decision
        ApplicationCampusesSurvey {
          id
          name
          logo
          decision
        }
        studentApplicationCampuses {
          id
          name
          logo
          applicationRequirement {
            id
            acceptRate
          }
        }
        otherApplicationDeadlines {
          id
          DeadlineName
          DeadlineDate
        }
        applicationRequirement {
          acceptRate
          regularDecisionDeadline
          earlyActionDeadline
          earlyDecisionDeadline
          college {
            parentMetaData
            id
            name
            admissionPageUrl
            logo
            customCollege
            styleConfig {
              bgDark
              bgLight
            }
            applicationRequirement {
              id
              earlyActionDeadline
              earlyDecisionDeadline
              regularDecisionDeadline
            }
          }

          year
        }
        completionPercentage
        completedAt

        studentApplicationEssays {
          id
          name
          status
          documentUrl
          completedAt
          createdAt
          updatedAt
          essayTemplate {
            id
            required
            commonEssay
            lengthLimit
            specificSchool
            requirementNote
            longDescription
            shortDescription
            templateYear
          }
        }
      }
      markedPremiumAt
      _clientStatus {
        UserId
        statusType
        statusValue
        isActive
      }
    }
  }
`;
// query gql for BSMD
export const GET_BSMD_STATUS = gql`
  query getBsmdStatusQuery($studentId: Int!) {
    getBsmdStatus(studentId: $studentId) {
      id,
      isBSMD
    }
  }
`;
// query gql for assist
export const GET_ASSIST_STATUS = gql`
  query getAssistStatusQuery($studentId: Int!) {
    getAssistStatus(studentId: $studentId) {
      id,
      isAssist
    }
  }
`;

export const SET_OTHER_APPLICATION = gql`
  mutation setOtherApplicationStatusMutation($studentId: Int!, $type: String!, $quantity: Int!, $desc: String!) {
    setOtherApplicationStatus(studentId: $studentId, type: $type, quantity: $quantity, desc: $desc) {
      success
    }
  }
`;

export const GET_OTHER_APPLICATION = gql`
  query getOtherApplicationQuery($studentId: Int!) {
    getOtherApplication(studentId: $studentId) {
      studentId,
      applicationType,
      applicationCount,
      applicationDescription,
    }
  }
`;

export const GET_COLLEGE_NAME = gql`
  query getCollegeNameQuery($studentId: Int!) {
    getAssistCollegeName(studentId: $studentId) {
      name,
    }
  }
`;

export const STUDENT_APPLICATIONS_QUERY_WITH_ESSAY_PROGRESS = gql`
  query allStudentApplicationsQueryWithEssayProgress($studentId: Int) {
    getStudentApplicationWithEssaysProgress(studentId: $studentId) {
      stuck {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      reviewReady {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      inProgress {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      NotStarted {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
      completed {
        applicationId
        name
        school {
          id
          name
          logo
          parentMetaData
        }
        dueDate
        description
        maxWord
      }
    }
  }
`;
type TParams = { id: string };

const StudentDetailsPage: FC<RouteComponentProps<TParams>> = ({
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const studentId = parseInt(id);
  const { user } = useContext(AppContext);
  const advisorId = user?.id;
  const [viewProgressBy, setViewProgressBy] = useState('school');
  const [openCalendar, setOpenCalendar] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [milestoneDialog, setMilestoneDialog] = useState<boolean>(false);
  const [otherApplicationDialog, setOtherApplicationDialog] = useState<boolean>(false);

  const [bsmdStatusArray, setBsmdStatusArray] = useState<(any | null)[]>([]);
  const [assistStatusArray, setAssistStatusArray] = useState<(any | null)[]>([]);
  // const [assistLength, setAssistLength] = useState<any>(0);
  const [assistTrueLength, setAssistTrueLength] = useState<any>(0);
  // const [bsmdLengh, setBsmdLength] = useState<any>(0);
  const [bsmdTrueLentgth, setBsmdTrueLength] = useState<any>(0);
  const [applicationType, setApplicationType] = useState<any>(null);
  const [extraApplicationCount, setExtraApplicationCount] = useState<any>(0);
  const [extraApplicationDesc, setExtraApplicationDesc] = useState<string>('');
  const [scholarshipApplicationCount, setScholarshipExtraApplicationCount] = useState<any>(0);
  const [scholarshipApplicationDesc, setScholarshipApplicationDesc] = useState<string>('');
  const [applicationName, setApplicationName] = useState<string>('');
  const [ucCount, setUcCount] = useState<any>(0);
  const [isRisk, setIsRisk] = useState<boolean>(false);

  const handleSaveToDB = async (
    type: string | null,
    quantity: number | 0,
    desc: string | '',
  ) => {
    try {
      const response = await client.mutate({
        mutation: SET_OTHER_APPLICATION,
        variables: {
          studentId,
          type,
          quantity,
          desc,
        },
      });

      if (response) {
        if (response?.data?.setOtherApplicationStatus.success) {
          if (type === 'extraCurrApplication') {
            setExtraApplicationCount(quantity);
            setExtraApplicationDesc(desc);
          } else {
            setScholarshipExtraApplicationCount(quantity);
            setScholarshipApplicationDesc(desc);
        }
      }
    }

    } catch (error) {
      console.log('SET_OTHER_APPLICATION error', error);
    }
  }

  const toggleOtherApplicationDialog = (args: string) => {
    setOtherApplicationDialog(!otherApplicationDialog);
    setApplicationType(args);
  };

  const toggleMilestoneDialog = () => {
    setMilestoneDialog(!milestoneDialog);
  };

  const urlParams = new URLSearchParams(window.location.search);
  const tab = urlParams.get('_t');
  const history = useHistory();

  const handleTabChange = (event: ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      history.push(`${history.location.pathname}?_t=info`);
    } else if (newValue === 1) {
      history.push(`${history.location.pathname}?_t=profile`);
    } else if (newValue === 2) {
      history.push(`${history.location.pathname}?_t=meetings`);
    } else if (newValue === 3) {
      history.push(`${history.location.pathname}?_t=applications`);
    } else if (newValue === 4) {
      history.push(`${history.location.pathname}?_t=opportunities`);
    } else if (newValue === 5) {
      history.push(`${history.location.pathname}?_t=timeLogs`);
    } else if (newValue === 6) {
      history.push(`${history.location.pathname}?_t=notes`);
    }
  };

  const applicationNavigation = () => {
    history.push(`${history.location.pathname}?_t=applications`);
  }

  const calculateApplicationCount = (flag: boolean) => {
    let bsmdCount:number = 0;
    assistStatusArray.map((obj) => {
      if (obj?.isAssist) {
        if(bsmdStatusArray[obj.id])
          bsmdCount++;
      }
    });
    setBsmdTrueLength(bsmdCount);
    return Number(assistTrueLength) + bsmdCount + ucCount + (flag ? 0 : Number(extraApplicationCount) + Number(scholarshipApplicationCount));
  }
  // query handler for BSMD
  const handleGetBsmdStatus = async () => {
    try {
      // change loading state to true 
      const response = await client.query({
        query: GET_BSMD_STATUS,
        variables: {
          studentId,
        },
        fetchPolicy: "network-only"
      });

      const bsmdArray: (any | null)[] = [];
      let bsmdCount: number = 0;

      if (response) {
        response?.data?.getBsmdStatus?.map((object: any, index: number) => {
          const appId: number = object?.id;
          const bsmdStatus: boolean = object?.isBSMD;
          bsmdCount++;
          bsmdArray[appId] = bsmdStatus;
        })
        setBsmdStatusArray(bsmdArray);
        // setBsmdLength(bsmdCount);
      } else {
        // set loading state to false  
        return false;
      }  
      // set loading state to false  
    } catch (error) {
      // set loading state to false

      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  }

   // query handler for Assist
   const handleGetAssistStatus = async () => {
    try {
      const response = await client.query({
        query: GET_ASSIST_STATUS,
        variables: {
          studentId,
        },
        fetchPolicy: "network-only"
      });

      const assistArray: (any | null)[] = [];
      let assistCount: number = 0;
      let assistTrueCount: number = 0; 

      if (response) {
        response?.data?.getAssistStatus?.map((object: any, index: number) => {
          const appId: number = object?.id;
          const assistStatus: boolean = object?.isAssist;
          assistCount++;
          if (assistStatus) assistTrueCount++;  
          assistArray[appId] = assistStatus;
        })
        setAssistTrueLength(assistTrueCount);
        // setAssistLength(assistCount);
        setAssistStatusArray(response?.data?.getAssistStatus);
      } else {
        return false;
      }
    } catch (error) {
      console.log('Error in SET_BSMD_STATUS Mutatio', error);
    }
  }
    // query handler for Other Application
  const handleOtherApplication = async () => {
    try {
      const response = await client.query({
        query: GET_OTHER_APPLICATION,
        variables: {
          studentId,
        },
        fetchPolicy: "network-only"
      });

      if (response?.data?.getOtherApplication) {
        response?.data?.getOtherApplication?.map((object: any, index: number) => {
          if (object.applicationType === 'extraCurrApplication') {
            setExtraApplicationCount(object.applicationCount);
            setExtraApplicationDesc(object.applicationDescription);
          } else {
            setScholarshipExtraApplicationCount(object.applicationCount);
            setScholarshipApplicationDesc(object.applicationDescription);
          }
        })
      } else {
        return false;
      }
    } catch (error) {
    console.log('handleotherapplication error', error);
    }
  }

  function removeDuplicates(arr:any) {
    return arr.filter((item:any, index:number) => arr.indexOf(item) === index);
  }

    // query handler for Other Application
  const handleCollegeName = async () => {
    try {
      const response = await client.query({
        query: GET_COLLEGE_NAME,
        variables: {
          studentId,
        },
        fetchPolicy: "network-only"
      });

      if (response) {
        let appNameArray:any =[];
        response?.data?.getAssistCollegeName?.map((application: any) => {
          appNameArray.push(application.name);
        });
        const duplicateRemoved:any = removeDuplicates(appNameArray);
      
        let BsmdIncrementer: number = 0;
        let allApplicationsName: string = '';
        let UCFlag: boolean = false;
        let allUCName: string = 'University of California*(';
        duplicateRemoved.map((schoolName:string) => {
          if (schoolName.includes('University of California - '))
          {
            allUCName = allUCName === 'University of California*(' ? allUCName + schoolName.split(' - ')[1] : allUCName + ', ' + schoolName.split(' - ')[1];
            UCFlag = true;
          }
          else
            allApplicationsName = allApplicationsName !== ''? allApplicationsName +', '+ schoolName : schoolName;    
        });

        if (UCFlag) {
          allApplicationsName = allUCName + '), ' + allApplicationsName;
          setUcCount(1);
        } else {
          setUcCount(0);
        }
        setApplicationName(allApplicationsName);
        return response;
      }
      return false;
    } catch (error) {
    console.log('handleotherapplication error', error);
    }
  }
    
  const handleRiskStatus = async () => {
    try {
      const { data } = await client.query({
        query: GET_RISK_STATUS,
        variables: {
          StudentId: studentId
        },
        fetchPolicy: "network-only"
      })
      setIsRisk(data?.getRiskStatus[0]?.status);
    } catch (error) {
      console.log('error on getting risk status', error);
    }
  }

  type creditType = number | null | undefined;
  const renderText = (text: string | null, title: string) => (
    <Box marginBottom={1} color={Colors.WHITE_ONE}>
      <Typography variant="h6">
        {title} {text}
      </Typography>
    </Box>
  );
  const includedInPackage = (
    videoCredits: creditType,
    essayReviews: creditType,
    shortName: string | null
  ) => {
    const packageInfo = [
      `${videoCredits} hours of one-on-one admissions coaching`,
      `${essayReviews} applications supported for essay editing`,
    ];
    if (
      shortName?.toLowerCase() === 'platinum' ||
      shortName?.toLowerCase() === 'ivy plus'
    ) {
      packageInfo.push(
        'Personal Admissions Expert with 2+ years of experience',
        'Former College Admissions Officer'
      );
    }
    return packageInfo;
  };

  const RadioButtonProgress: FC = () => {
    return (
      <FormControl
        component="fieldset"
        className={`${tableClasses.fieldMobileResponsive}`}
      >
        <Typography variant="h6" className="pr-4">
          VIEW PROGRESS BY
        </Typography>
        <RadioGroup
          defaultValue={viewProgressBy}
          row
          onChange={(e) => setViewProgressBy(e.target.value)}
        >
          <FormControlLabel
            value="school"
            control={<Radio color="primary" />}
            label="School"
          />
          <FormControlLabel
            value="essay"
            control={<Radio color="primary" />}
            label="Essay"
          />
        </RadioGroup>
      </FormControl>
    );
  };

  const RenderApplicationWithEssayProgress: FC = () => {
    return (
      <Query<allStudentApplicationsQueryWithEssayProgress>
        query={STUDENT_APPLICATIONS_QUERY_WITH_ESSAY_PROGRESS}
        variables={{ studentId }}
        fetchPolicy="network-only"
      >
        {({ data, refetch, loading, networkStatus, fetchMore }) => {
          if (!data || !data.getStudentApplicationWithEssaysProgress)
            return null;
          if (loading) return null;
          const { getStudentApplicationWithEssaysProgress } = data;
          const { stuck, reviewReady, completed, inProgress, NotStarted } =
            getStudentApplicationWithEssaysProgress;

          if (
            !stuck ||
            !reviewReady ||
            !completed ||
            !inProgress ||
            !NotStarted
          )
            return null;
          return (
            <ApplicationsWithEssayProgress
              stuck={stuck}
              reviewReady={reviewReady}
              completed={completed}
              inProgress={inProgress}
              NotStarted={NotStarted}
            />
          );
        }}
      </Query>
    );
  };

  useEffect(() => {
    handleGetAssistStatus();
    handleGetBsmdStatus();
    handleOtherApplication();
    handleCollegeName();
    handleRiskStatus();
  }, [window.location.href]);

  useEffect(() => {
    if (tab) {
      const _tabValue = StudentDetailsTabs[tab];
      if (_tabValue) {
        setTabValue(_tabValue);
      } else {
        setTabValue(0);
      }
    } else {
      setTabValue(0);
    }
  }, [tab]);
  const tableClasses = useStylesTable();
  return (
    <Query<StudentDetailQuery>
      query={STUDENT_DETAIL_QUERY}
      variables={{ studentId, advisorId }}
      fetchPolicy="no-cache"
    >
      {({ data, loading, refetch }) => {
        if (loading) return <Loader />;
        if (!data || !data.Student) return null;
        const { Student } = data;
        const {
          firstName,
          lastName,
          email,
          phoneNumber,
          pictureUrl,
          highSchool,
          highSchoolYear,
          gapYear,
          sendBirdChannelUrl,
          gpa,
          act,
          sat,
          userToken,
          parents,
          isPremium,
          allStudentMentors,
          studentApplications,
          shortName,
          color,
          premiumPackageType,
          premiumTotalEssayCount,
          premiumTotalVideoCount,
          premiumVideoCountUsed,
          totalMilestoneVideoChatUsed,
          premiumTotalOfflineCount,
          premiumOfflineCountUsed,
          initialKickOffDate,
          intakeResponse,
          intakeFormToken,
          roles,
          studentDocuments,
          AdmissionPackageType,
          shareDriveUrl,
          markedPremiumAt,
          firstMeetingBookedDate,
          studentApplicationSurvey,
          planCancelled,
          graduated,
          StudentIncentiveBonus,
          isMBAGrad,
          _clientStatus,
        } = Student;
        const studentName = `${firstName || ''} ${lastName || ''}`;
        const studentIntake = intakeResponse && JSON.parse(intakeResponse);
        const IncentiveBonusPayable =
          StudentIncentiveBonus?.IncentiveBonusPayable || false;
        let offlineCountUsed = 0;
        const isCapEnrollee = _clientStatus?.statusValue === CAP_ENROLLEE;
        if (premiumOfflineCountUsed) {
          const {
            MeetingWithAdvisor,
            Other,
            essayCount,
            milestoneTeamTotal,
            offlineResearch,
            dressRehearsalCount,
            correspondenceTotal,
          } = premiumOfflineCountUsed;
          offlineCountUsed =
            MeetingWithAdvisor +
            Other +
            essayCount +
            milestoneTeamTotal +
            offlineResearch +
            dressRehearsalCount +
            correspondenceTotal;
        }
        const isBookingDisabled = Boolean(
          ((premiumVideoCountUsed || 0) >= (premiumTotalVideoCount || 0) ||
            planCancelled ||
            graduated) || ( isRisk && isPremium )
        );

        const hasSpecialAdvisor = allStudentMentors?.filter((record) =>
          associationTypeOptions.find(
            (option) => option.value === record?.associateType
          )
        )?.length;

        const maxBonusIncentive =
          shortName?.length &&
          getStudentBonus({
            shortName,
          });
        const isVideoChatHoursExhausted =
          (premiumVideoCountUsed || 0) >= (premiumTotalVideoCount || 0);
        const isOfflinehoursExhausted =
          (offlineCountUsed || 0) >= (premiumTotalOfflineCount || 0);
        return (
          <>
            <Header />

            {user && (
              <AdvisorBookingModal
                setOpenCalendar={setOpenCalendar}
                advisorId={user.id}
                openCalendar={openCalendar}
                student={{
                  id: studentId,
                  email: email || '',
                  firstName,
                  lastName,
                  premiumTotalVideoCount,
                  premiumVideoCountUsed,
                  allStudentMentors,
                  firstMeetingBookedDate,
                  initialKickOffDate,
                }}
              />
            )}

            <Grid className={classes.genericBreadcrumb}>
              <Container maxWidth={false}>
                <Grid>
                  <Link to="/students">
                    <img src={Breadcrumb_Arrow_Icon} alt="arrow" />
                  </Link>
                </Grid>

                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  className={classes.mobileBreadcrumb}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    className="studentInformation"
                  >
                    <Grid>
                      {pictureUrl ? (
                        <Grid
                          className="studentAvatar"
                          style={{
                            backgroundImage: 'url(' + pictureUrl + ')',
                          }}
                        />
                      ) : (
                        <Grid
                          className="studentAvatar"
                          style={{
                            backgroundImage: 'url(' + USER_ICON + ')',
                          }}
                        />
                      )}
                    </Grid>

                    <Box>
                      <Tooltip classes={tooltipClasses} title={studentName}>
                        <Typography variant="h3" noWrap>
                          {studentName}
                        </Typography>
                      </Tooltip>

                      <Typography variant="h6">
                        {highSchoolYear
                          ? renderText(highSchoolYear || '--', 'Class of ')
                          : ''}
                      </Typography>
                    </Box>
                  </Box>

                  <Box display="flex" flexDirection="column">
                    <Link
                      to={`/chat/${sendBirdChannelUrl}`}
                      onClick={(e) => planCancelled && e.preventDefault()}
                    >
                      <Button
                        variant="contained"
                        disabled={Boolean(planCancelled)}
                        className={planCancelled ? classes.disabledButton : ''}
                      >
                        <img src={Chat_Icon} alt="chat" />
                        <span className="textSpan">Chat Now</span>
                      </Button>
                    </Link>

                    {user?.isActive && (
                      <Button
                        className={
                          isBookingDisabled ? classes.disabledButton : ''
                        }
                        variant="contained"
                        disabled={isBookingDisabled}
                        onClick={() => {
                          if (
                            (premiumVideoCountUsed || 0) <
                              (premiumTotalVideoCount || 0) &&
                            !planCancelled &&
                            !graduated
                          ) {
                            setOpenCalendar(true);
                          }
                        }}
                      >
                        <img src={BOOK_MEETING_ICON} alt="chat" />
                        <span className="textSpan">Book Meeting</span>
                      </Button>
                    )}
                  </Box>
                </Box>
              </Container>
            </Grid>

            <Grid className={classes.studentInformationWrapper}>
              <Container maxWidth={false}>
                { isPremium && isRisk ? <BannerMessage /> : null }
                {isPremium &&
                  (isVideoChatHoursExhausted || isOfflinehoursExhausted) && (
                    <AlertInfoCard />
                  )}

                <Tabs
                  className="rootTabs"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="General" {...tabA11yProps(0)} />
                  <Tab label="Student Profile" {...tabA11yProps(1)} />
                  <Tab label="Meetings" {...tabA11yProps(2)} />
                  <Tab
                    hidden={isMBAGrad || false}
                    label="Applications"
                    {...tabA11yProps(3)}
                  />
                  <Tab
                    hidden={isMBAGrad || false}
                    label="Opportunities"
                    {...tabA11yProps(4)}
                  />
                  <Tab label="Time Logs (NEW)" {...tabA11yProps(5)} />

                  <Tab label="Student Notes" {...tabA11yProps(6)} />
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                  <>
                    <Grid container>
                      {planCancelled ? (
                        <Grid xs={12}>
                          <Box className={classes.cancelledPlanBox}>
                            <img src={Caution_Icon} alt="caution" />
                            <Box className="typoBox">
                              <Typography className="title">
                                This student has canceled their plan.
                              </Typography>
                              <Typography className="hint">
                                Video chat hours and entitlements are now
                                frozen. Meetings cannot be scheduled at this
                                time.
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ) : null}

                      <Grid item xs={12} sm={12} md={7} lg={7}>
                        {!isMBAGrad && (
                          <Box
                            className={classes.shareDriveUrl}
                            display="flex"
                            alignItems="center"
                          >
                            <Typography variant="body1">
                              GOOGLE DRIVE FOLDER:
                            </Typography>
                            {shareDriveUrl?.length ? (
                              <a href={shareDriveUrl} target="_blank">
                                {shareDriveUrl}
                              </a>
                            ) : (
                              '--'
                            )}
                          </Box>
                        )}

                        <Grid className={classes.personalInfoStudent}>
                          <Typography
                            variant="h4"
                            className={classes.studentSectionHeader}
                          >
                            Student Info
                          </Typography>

                          <Box display="flex" pb={2} className="emailPhone">
                            {email ? (
                              <a href={`mailto:${email}`}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  className="emailSpacing"
                                >
                                  <img src={MailIcon} alt="mail" />
                                  <Tooltip title={email || '--'}>
                                    <Typography variant="body2">
                                      {email || '--'}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                className="emailSpacing"
                              >
                                <img src={MailIcon} alt="mail" />
                                <Tooltip title={email || '--'}>
                                  <Typography variant="body2">
                                    {' '}
                                    {email || '--'}
                                  </Typography>
                                </Tooltip>
                              </Box>
                            )}

                            {phoneNumber ? (
                              <a href={`tel:${phoneNumber}`}>
                                <Box display="flex" alignItems="center">
                                  <img src={CallIcon} alt="call" />
                                  <Typography variant="body2">
                                    {' '}
                                    {phoneNumber || '--'}
                                  </Typography>
                                </Box>
                              </a>
                            ) : (
                              <Box display="flex" alignItems="center">
                                <img src={CallIcon} alt="call" />
                                <Typography variant="body2">
                                  {phoneNumber || '--'}
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          {!isMBAGrad && (
                            <Grid className={classes.educationInformation}>
                              <Box display="flex" alignItems="center">
                                <Typography variant="h6">
                                  <strong>High School:</strong>
                                </Typography>

                                <Typography variant="body2">
                                  {highSchool}
                                </Typography>
                              </Box>

                              <Box display="flex" alignItems="center">
                                <Typography variant="h6">GPA:</Typography>

                                <Typography variant="body2">
                                  {gpa ? gpa : '--'}
                                </Typography>
                              </Box>

                              <Box display="flex" alignItems="center">
                                <Typography variant="h6">SAT:</Typography>

                                <Typography variant="body2">
                                  {sat ? sat : '--'}
                                </Typography>
                              </Box>

                              <Box display="flex" alignItems="center">
                                <Typography variant="h6">Act:</Typography>

                                <Typography variant="body2">
                                  {act ? act : '--'}
                                </Typography>
                              </Box>

                              <Box className="excludeCheckbox">
                                <ExcludeStudentFromWeeklyAdvisorReportCheckbox
                                  StudentId={studentId}
                                  AdvisorId={advisorId || 0}
                                />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                        {!isMBAGrad && (
                          <Box overflow="auto">
                            <ParentTable
                              title={'Parent/Guardian Info'}
                              parents={parents}
                            />
                          </Box>
                        )}
                        <Milestones
                          title={'Milestones'}
                          kickoffDate={initialKickOffDate}
                          studentIntakeResponse={studentIntake}
                          formToken={intakeFormToken || ''}
                          parents={parents}
                          isMBAGrad={isMBAGrad || false}
                        />
                        {isMBAGrad && (
                          <Grid>
                            <Typography
                              variant="h4"
                              className={clsx(
                                classes.studentSectionHeader,
                                classes.studentAdvisorSpacing
                              )}
                            >
                              Advisors
                            </Typography>
                            {sortAdvisorsWithLevel(
                              allStudentMentors?.filter(
                                (record) =>
                                  !associationTypeOptions.find(
                                    (option) =>
                                      option.value === record?.associateType
                                  )
                              )
                            )?.map((advisor) => (
                              <Advisors
                                sendBirdChannelUrl={
                                  advisor?.mentor?.sendBirdChannelUrl
                                }
                                id={advisor?.mentor?.id}
                                firstName={advisor?.mentor?.firstName}
                                lastName={advisor?.mentor?.lastName}
                                pictureUrl={advisor?.mentor?.pictureUrl}
                                college={advisor?.mentor?.currentCollege?.name}
                                email={advisor?.mentor?.email}
                                matchedAt={advisor?.startAt}
                                graduatedAt={advisor?.mentor?.intakeYear}
                                sendBirdId={advisor?.mentor?.sendbirdId}
                                advisorLevel={
                                  advisor?.mentor?.advisorLevel || ''
                                }
                              />
                            ))}
                            {hasSpecialAdvisor ? (
                              <Box pb={2.3} fontSize={14}>
                                <Typography
                                  variant="h6"
                                  className={classes.subAdvisorHeading}
                                >
                                  SPECIALTY ADVISORS
                                </Typography>
                              </Box>
                            ) : null}
                            {sortAdvisorsWithLevel(
                              allStudentMentors?.filter((record) =>
                                associationTypeOptions.find(
                                  (option) =>
                                    option.value === record?.associateType
                                )
                              )
                            )?.map((advisor) => (
                              <Advisors
                                sendBirdChannelUrl={
                                  advisor?.mentor?.sendBirdChannelUrl
                                }
                                id={advisor?.mentor?.id}
                                firstName={advisor?.mentor?.firstName}
                                lastName={advisor?.mentor?.lastName}
                                pictureUrl={advisor?.mentor?.pictureUrl}
                                college={advisor?.mentor?.currentCollege?.name}
                                email={advisor?.mentor?.email}
                                matchedAt={advisor?.startAt}
                                graduatedAt={advisor?.mentor?.intakeYear}
                                sendBirdId={advisor?.mentor?.sendbirdId}
                                advisorLevel={
                                  associationTypeOptions.find(
                                    (option) =>
                                      option.value === advisor?.associateType
                                  )?.label ||
                                  advisor?.mentor?.advisorLevel ||
                                  ''
                                }
                              />
                            ))}
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
                      <Grid item xs={12} sm={12} md={5} lg={4}>
                        {isPremium && (
                          <>
                            <Typography
                              variant="h4"
                              className={classes.studentSectionHeader}
                            >
                              Package Details
                            </Typography>

                            <Grid className={classes.packageDetail}>
                              <>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    variant="h6"
                                    className="elite"
                                    style={{ color: `${color}` }}
                                  >
                                    {shortName?.length
                                      ? shortName
                                      : premiumPackageType}
                                  </Typography>

                                  {/* {maxBonusIncentive !== 'N/A' &&
                                    IncentiveBonusPayable && (
                                      <Link to="/time-logs?payouts=true&fromStudentPage=true">
                                        <Button
                                          variant="contained"
                                          className="incentiveNavigateButton"
                                        >
                                          Max Bonus Incentive:{' '}
                                          {maxBonusIncentive}
                                        </Button>
                                      </Link>
                                    )} */}
                                </Box>

                                <Typography variant="body2">
                                  Enrolled Since:
                                  {markedPremiumAt
                                    ? moment(markedPremiumAt).format(
                                        ' MMMM DD, YYYY'
                                      )
                                    : ' Unavailable'}
                                  {isCapEnrollee ? ' (CollegeAidPro)' : ''}
                                </Typography>

                                {true &&
                                (premiumTotalVideoCount ||
                                  premiumTotalVideoCount === 0) &&
                                isPremium ? (
                                  (premiumVideoCountUsed || 0) <
                                  premiumTotalVideoCount ? (
                                    <VideoChatHours
                                      totalHours={premiumTotalVideoCount}
                                      totalMilestoneVideoChatUsed={
                                        totalMilestoneVideoChatUsed
                                      }
                                      hoursUsed={premiumVideoCountUsed || 0}
                                      text={'video chat hours used'}
                                      toggleMilestoneDialog={() => {
                                        toggleMilestoneDialog();
                                      }}
                                    />
                                  ) : (
                                    <VideoChatHoursEnd
                                      totalHours={premiumTotalVideoCount}
                                      hoursUsed={premiumVideoCountUsed || 0}
                                      text={'video chat hours used'}
                                      toggleMilestoneDialog={() => {
                                        toggleMilestoneDialog();
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}

                                {true &&
                                (premiumTotalOfflineCount ||
                                  premiumTotalOfflineCount === 0) &&
                                isPremium ? (
                                  (offlineCountUsed || 0) <
                                  (premiumTotalOfflineCount || 0) ? (
                                    <VideoChatHours
                                      totalHours={premiumTotalOfflineCount || 0}
                                      hoursUsed={offlineCountUsed || 0}
                                      text={'offline hours used'}
                                      premiumOfflineCountUsed={
                                        premiumOfflineCountUsed
                                      }
                                      toggleMilestoneDialog={() => {
                                        toggleMilestoneDialog();
                                      }}
                                    />
                                  ) : (
                                    <VideoChatHoursEnd
                                      totalHours={premiumTotalOfflineCount || 0}
                                      hoursUsed={offlineCountUsed || 0}
                                      text={'offline hours used'}
                                      premiumOfflineCountUsed={
                                        premiumOfflineCountUsed
                                      }
                                      toggleMilestoneDialog={() => {
                                        toggleMilestoneDialog();
                                      }}
                                    />
                                  )
                                ) : (
                                  <></>
                                )}
                                <MilestoneActivityDialog
                                  isOpen={milestoneDialog}
                                  onClose={() => {
                                    toggleMilestoneDialog();
                                  }}
                                  userId={Student.id}
                                  name={
                                    Student.firstName + ' ' + Student.lastName
                                  }
                                  plan={Student.shortName || ''}
                                  isGraduateStudent={Student.graduated ?? false}
                                  isMBAGrad={isMBAGrad ?? false}
                                />

                                {/* 2902 */}
                                {
                                  <ApplicationEntitlement
                                    studentId={studentId}
                                    totalHours={premiumTotalEssayCount || 0}
                                    // myApplicationCount={Number(assistTrueLength) + Number(bsmdTrueLentgth) + ucCount}
                                    // hoursUsed={Number(assistTrueLength) + Number(bsmdTrueLentgth) + Number(extraApplicationCount) + Number(scholarshipApplicationCount) + ucCount || 0}
                                    myApplicationCount={calculateApplicationCount(true)}
                                    hoursUsed={calculateApplicationCount(false)}
                                    studentApplications={applicationName}
                                    text={'applications used'}
                                    premiumOfflineCountUsed={
                                      premiumOfflineCountUsed
                                    }
                                    tabNavigation={() => {applicationNavigation();}}
                                    toggleOtherApplicationDialog={(args) => {
                                      toggleOtherApplicationDialog(args);
                                    }}
                                    extraApplicationCount={extraApplicationCount}
                                    extraApplicationDesc={extraApplicationDesc}
                                    scholarshipApplicationCount={scholarshipApplicationCount}
                                    scholarshipApplicationDesc={scholarshipApplicationDesc}
                                  />
                                }
                                <OtherApplicationDialogBox
                                  StudentId={studentId}
                                  openDialog={otherApplicationDialog}
                                  handleClose={toggleOtherApplicationDialog}
                                  applicationType={applicationType}
                                  quantity={applicationType === 'extraCurrApplication' ? extraApplicationCount : scholarshipApplicationCount}
                                  description={applicationType === 'extraCurrApplication' ? extraApplicationDesc : scholarshipApplicationDesc}
                                  saveData={(type: string, quantity: number, desc: string) => handleSaveToDB(type, quantity, desc)}
                                />
                                {/* 2902 */}
                                {AdmissionPackageType !== 'Custom' ? (
                                  <Grid className={classes.includeBox}>
                                    <Typography variant="h6">
                                      INCLUDES:
                                    </Typography>

                                    <ul>
                                      {(() => {
                                        const detailsList: (
                                          | string
                                          | React.ReactNode
                                        )[] = includedInPackage(
                                          premiumTotalVideoCount,
                                          premiumTotalEssayCount,
                                          shortName?.length
                                            ? shortName
                                            : premiumPackageType
                                        );
                                        if (
                                          premiumPackageType &&
                                          premiumPackageType.includes(
                                            'Polygence'
                                          )
                                        ) {
                                          detailsList.push(
                                            <Fragment>
                                              "Build Your Own Research Project”
                                              Add-On with <i>Polygence</i>
                                            </Fragment>
                                          );
                                        }
                                        return detailsList.map(
                                          (details, index) => (
                                            <li key={index}>{details}</li>
                                          )
                                        );
                                      })()}
                                    </ul>
                                  </Grid>
                                ) : null}
                              </>
                            </Grid>
                          </>
                        )}

                        {!isMBAGrad && (
                          <Grid>
                            <Typography
                              variant="h4"
                              className={clsx(
                                classes.studentSectionHeader,
                                classes.studentAdvisorSpacing
                              )}
                            >
                              Advisors
                            </Typography>
                            {sortAdvisorsWithLevel(
                              allStudentMentors?.filter(
                                (record) =>
                                  !associationTypeOptions.find(
                                    (option) =>
                                      option.value === record?.associateType
                                  )
                              )
                            )?.map((advisor) => (
                              <Advisors
                                sendBirdChannelUrl={
                                  advisor?.mentor?.sendBirdChannelUrl
                                }
                                id={advisor?.mentor?.id}
                                firstName={advisor?.mentor?.firstName}
                                lastName={advisor?.mentor?.lastName}
                                pictureUrl={advisor?.mentor?.pictureUrl}
                                college={advisor?.mentor?.currentCollege?.name}
                                email={advisor?.mentor?.email}
                                matchedAt={advisor?.startAt}
                                graduatedAt={advisor?.mentor?.intakeYear}
                                sendBirdId={advisor?.mentor?.sendbirdId}
                                advisorLevel={
                                  advisor?.mentor?.advisorLevel || ''
                                }
                              />
                            ))}
                            {hasSpecialAdvisor ? (
                              <Box pb={2.3} fontSize={14}>
                                <Typography
                                  variant="h6"
                                  className={classes.subAdvisorHeading}
                                >
                                  SPECIALTY ADVISORS
                                </Typography>
                              </Box>
                            ) : null}
                            {sortAdvisorsWithLevel(
                              allStudentMentors?.filter((record) =>
                                associationTypeOptions.find(
                                  (option) =>
                                    option.value === record?.associateType
                                )
                              )
                            )?.map((advisor) => (
                              <Advisors
                                sendBirdChannelUrl={
                                  advisor?.mentor?.sendBirdChannelUrl
                                }
                                id={advisor?.mentor?.id}
                                firstName={advisor?.mentor?.firstName}
                                lastName={advisor?.mentor?.lastName}
                                pictureUrl={advisor?.mentor?.pictureUrl}
                                college={advisor?.mentor?.currentCollege?.name}
                                email={advisor?.mentor?.email}
                                matchedAt={advisor?.startAt}
                                graduatedAt={advisor?.mentor?.intakeYear}
                                sendBirdId={advisor?.mentor?.sendbirdId}
                                advisorLevel={
                                  associationTypeOptions.find(
                                    (option) =>
                                      option.value === advisor?.associateType
                                  )?.label ||
                                  advisor?.mentor?.advisorLevel ||
                                  ''
                                }
                              />
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </>
                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                  {isMBAGrad ? (
                    <MBAStudentProfile studentId={studentId || null} />
                  ) : (
                    <StudentProfile studentId={studentId} />
                  )}
                </TabPanel>

                <TabPanel value={tabValue} index={2}>
                  <Grid container>
                    <Grid xs={12}>
                      <MeetingsTable
                        title={'Upcoming Meetings'}
                        email={Student?.email || ''}
                        studentId={Student.id}
                        roles={roles}
                        refetch={refetch}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={3}>
                  <Grid xs={12}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className={`${classes.tableAccordion} d-flex justify-content-between align-items-center pb-1`}
                    >
                      <Typography variant="h4" className="appTitle">
                        Applications {`(${studentApplications?.length})`}
                      </Typography>
                      {checkForCurrentYear(highSchoolYear) && (
                        <Button
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_STUDENT_PORTAL_URL}/survey-form/${userToken}`
                            );
                            setIsLinkCopied(true);
                            setTimeout(() => {
                              setIsLinkCopied(false);
                            }, 5000);
                          }}
                          variant="contained"
                          className={
                            isLinkCopied
                              ? clsx(classes.linkForm, classes.linkFormCopied)
                              : classes.linkForm
                          }
                        >
                          {isLinkCopied ? (
                            <span className={classes.LinkButtonText}>
                              <img src={LINK_ICON} alt="link" />
                              Link Copied
                            </span>
                          ) : (
                            <span className={classes.LinkButtonText}>
                              <img src={LINK_COPY_ICON} alt="link" />
                              Copy Acceptance Survey Link
                            </span>
                          )}
                        </Button>
                      )}
                    </Grid>
                    <Box className={`${classes.tableAccordion}`}>
                      <Box>
                        <RadioButtonProgress />
                      </Box>
                      {viewProgressBy === 'school' ? (
                        <StudentApplicationsTable
                          title={'Applications'}
                          premiumTotalEssayCount = {premiumTotalEssayCount || 0}
                          extraApplicationCount = {extraApplicationCount || 0}
                          scholarshipApplicationCount = {scholarshipApplicationCount || 0}
                          applications={studentApplications}
                          user={user}
                          refetch={refetch}
                          highSchoolYear={highSchoolYear}
                          studentId={studentId}
                          gapYear={gapYear || false}
                          graduated={graduated || false}
                        />
                      ) : (
                        <RenderApplicationWithEssayProgress />
                      )}
                    </Box>
                  </Grid>
                  <Grid xs={12} className={`${classes.tableAccordion} pb-5`}>
                    <AdditionalSurveyDetails
                      studentApplicationSurvey={studentApplicationSurvey}
                    />
                  </Grid>
                  <Grid xs={12}>
                    {studentApplications && (
                      <StudentEssaysTable
                        title="Student's Documents"
                        applications={studentApplications}
                        studentDocuments={studentDocuments}
                      />
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value={tabValue} index={4}>
                  <FindOpportunity
                    StudentId={studentId}
                    name={Student?.firstName}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={5}>
                  <StudentTimeLogs
                    StudentId={studentId}
                    markedPremiumAt={markedPremiumAt}
                  />
                </TabPanel>

                <TabPanel value={tabValue} index={6}>
                  <StudentNotes StudentId={studentId} />
                </TabPanel>
              </Container>
            </Grid>

            <FooterNew />
          </>
        );
      }}
    </Query>
  );
};

export default StudentDetailsPage;
