import gql from 'graphql-tag';

export const STUDENT_APPLICATION_QUERY = gql`
  query StudentApplicationDetailNewQuery($studentApplicationId: Int!) {
    StudentApplication(studentApplicationId: $studentApplicationId) {
      id
      Student {
        id
        name
        pictureUrl
        firstName
        graduated
        highSchoolYear
        gapYear
      }
      completedAt
      applicationStatus
      decisionStatus
      decisionDeadline
      graduatedEarlyDecisionDeadline
      graduatedRegularDecisionDeadline
      graduatedEarlyActionDeadline
      decision
      ApplicationCampusesSurvey {
        id
        name
        logo
        decision
      }
      commonEssays {
        id
        name
        status
        documentUrl
        completedAt
        essayStatus
        orderItem {
          id
          completed
          order {
            id
            status
            mentor {
              id
              firstName
              pictureUrl
              uuid
            }
          }
        }
        essayTemplate {
          id
          required
          commonEssay
          lengthLimit
          specificSchool
          requirementNote
          longDescription
          shortDescription
          essayTitle
          deletedAt
          Order
        }
      }
      otherApplicationDeadlines {
        id
        DeadlineName
        DeadlineDate
      }
      studentApplicationCampuses {
        id
        name
        logo
        styleConfig {
          bgDark
          bgLight
        }
      }
      applicationRequirement {
        id
        year
        acceptsCommonApp
        acceptsCoalitionApp
        earlyActionDeadline
        earlyDecisionDeadline
        regularDecisionDeadline
        regularActionDeadline
        financialAidEarlyActionDeadline
        financialAidEarlyDecisionDeadline
        financialAidRegularActionDeadline
        financialAidRegularDecisionDeadline
        earlyActionDecisionsBy
        earlyDecisionDecisionsBy
        regularActionDecisionsBy
        regularDecisionDecisionsBy
        college {
          id
          name
          admissionPageUrl
          logo
          isUCCollege
          Campuses {
            id
            name
          }

          styleConfig {
            bgDark
            bgLight
          }
          parentMetaData
          Campuses {
            id
            name
          }
          allMajors {
            id
            name
            otherMajor
            majorCategory {
              id
              name
              displayName
            }
          }
          mentors {
            id
            firstName
            lastName
            pictureUrl
            bio
            intakeYear
            gpa
            major
            minor
            isAcceptedTo
            advisorLevel
            previouslyWorkedColleges {
              college {
                id
                name
                logo
              }
            }
            graduateSchool {
              id
              name
              logo
            }
            graduateSchoolGradYear
            currentStudents {
              id
            }

            mentorReviews {
              id
              rating
              message
            }
            colleges {
              id
              logo
              name
            }
            currentCollege {
              id
              name
              logo
            }

            uuid
          }
        }
      }
      studentApplicationEssays {
        id
        name
        status
        documentUrl
        completedAt
        essayStatus
        orderItem {
          id
          completed
          order {
            id
            status
            mentor {
              id
              firstName
              pictureUrl
              uuid
            }
          }
        }
        essayTemplate {
          id
          required
          commonEssay
          lengthLimit
          specificSchool
          requirementNote
          longDescription
          shortDescription

          essayTitle
          deletedAt
          Order
        }
      }
      studentApplicationMajors {
        id
        name
        MajorId
        otherMajor
        ApplicationCampusId
        majorCategory {
          id
          name
        }
      }
      studentApplicationChecklist {
        id
        StudentApplicationId
        accountCreated
        testScoresSubmitted
        transcriptRequested
        recommendationLetterRequested
        otherNotes
        accountCreatedCompletedAt
        testScoresSubmittedCompletedAt
        transcriptRequestedCompletedAt
        recommendationLetterRequestedCompletedAt
        otherNotesCompletedAt
      }
    }
  }
`;

export const OTHER_ESSAY_QUERY = gql`
  query studentApplicationOtherEssays($applicationId: Int!) {
    studentApplicationOtherEssays(applicationId: $applicationId) {
      id
      title
      wordMax
      prompt
      documentUrl
      essayStatus
      documentName
      isOtherEssay
      applicationEssayId
      archivedAt
    }
  }
`;

export const GET_BSMD_STATUS = gql`
  query getBsmdStatusStudentQuery($studentId: Int!) {
    getBsmdStatus(studentId: $studentId) {
      id,
      isBSMD,
      ApplicationRequirementId,
    }
  }
`;

export const GET_ASSIST_STATUS = gql`
  query getAssistStatusStudentTableQuery($studentId: Int!) {
    getAssistStatus(studentId: $studentId) {
      id,
      isAssist,
      ApplicationRequirementId,
    }
  }
`;

export const GET_APP_COUNT_USED = gql`
  query getAppCountUsedQuery($studentId: Int!) {
    getApplicationCountUsed(StudentId: $studentId) {
      StudentId,
      entitlementType,
      entitlementCountUsed,
    }
  }
`;
